import App from '../App.vue';
import Login from '../components/Login.vue';
import Home from '../components/home';

import Users from '../components/users';
import Profile from '../components/users/Profile.vue';
import Orders from '../components/orders';
import OrdersList from '../components/orders/List.vue';
import Estimates from '../components/estimates';
import EstimatesList from '../components/estimates/List.vue';
import EditOrder from '../components/estimates/Edit.vue';
import NewAltechProduct from '../components/estimates/products/NewAltechProduct.vue';
import NewCustomProduct from '../components/estimates/products/NewCustomProduct.vue';
import EstimateProductList from '../components/estimates/products/List.vue';
import ProductChooser from '../components/estimates/products/AltechProduct/ProductChooser.vue';
import ProductDetails from '../components/estimates/products/AltechProduct/ProductDetails';

import store from '../stores'

export default [
    { path: '/', component: App, children: [
        { path: '', component: Home },
        { path: 'compte', component: Profile },
        { path: 'login', component: Login },
        { path: 'gestion-comptes', component: Users },
        { path: 'commandes', component: Orders, children: [
            { path: '', component: OrdersList },
        ] },
        { path: 'devis', component: Estimates, children: [
            { path: '', component: EstimatesList },
            { path: ':id', component: EditOrder, children: [
                { path: '', component: EstimateProductList },
                { path: 'altech', component: NewAltechProduct, children: [
                    {path: '', component: ProductChooser},
                    {path: 'etape2', component: ProductDetails}

                ] },
                { path: 'perso', component: NewCustomProduct },
            ] },
        ] },


    ] },
];
