<template>
    <div class="modal-backdrop">
        <div class="modal" tabindex="-1" role="dialog" style="display: block" >
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b> Nouveau devis</b>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="$store.commit('hideModal')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form @submit.prevent="save">
                        <div class="row p-4">
                            <div class="mb-3 col-lg-12">
                                <ul class="list-unstyled pb-0 mb-0">
                                    <li class="mb-2 row">
                                        <div class="col-md-6" :class="{ 'text-danger': submitted && $v.quote.title.$error}">Nom du chantier * : </div>
                                        <div class="col-md-6">
                                            <input v-model="quote.title" type="text" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && $v.quote.title.$error}"/>
                                            <div class="text-danger small" v-if="submitted && $v.quote.title.$error"><b>Nom du chantier obligatoire</b></div>
                                        </div>
                                    </li>
                                    <li class="mb-2 row">
                                        <div class="col-md-6">Nom du client : </div>
                                        <div class="col-md-6">
                                            <input v-model="quote.contact" type="text" class="form-control form-control-sm"/>
                                        </div>
                                    </li>
                                    <li class="mb-2 row">
                                        <div class="col-md-6">Adresse : </div>
                                        <div class="col-md-6">
                                            <input v-model="quote.address" type="text" class="form-control form-control-sm"/>
                                        </div>
                                    </li>
                                    <li class="mb-2 row">
                                        <div class="col-md-6">CP / Ville : </div>
                                        <div class="col-md-6">
                                            <input v-model="quote.cpcity" type="text" class="form-control form-control-sm"/>
                                        </div>
                                    </li>
                                    <li class="mb-2 row">
                                        <div class="col-md-6" :class="{ 'text-danger': submitted && $v.quote.vat.$error}">TVA Devis * : </div>
                                        <div class="col-md-6">
                                            <select class="form-control form-control-sm" v-model="quote.vat" :class="{ 'is-invalid': submitted && $v.quote.vat.$error}">
                                                <option :value="5.5">5,5 %</option>
                                                <option :value="10">10 %</option>
                                                <option :value="20">20 %</option>
                                            </select>
                                            <!-- <input v-model="quote.vat" type="number" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && $v.quote.vat.$error}"/> -->
                                            <div class="text-danger small" v-if="submitted && $v.quote.vat.$error"><b>TVA obligatoire</b></div>
                                        </div>
                                    </li>
                                    <li class="mb-2 row">
                                        <div class="col-md-6">Affaire suivie par : </div>
                                        <div class="col-md-6">
                                            <input v-model="quote.manager" type="text" class="form-control form-control-sm"/>
                                        </div>
                                    </li>
                                    <li class="mb-2 row">
                                        <div class="col-md-6">Date de première visite préalable : </div>
                                        <div class="col-md-6">
                                            <input class="form-control form-control-sm" id="date" type="date" v-model="quote.first_visit_date">
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" v-on:click="$store.commit('hideModal')">Annuler</button>
                        <button type="button" class="btn btn-info" @click="save">
                            <i class="fas fa-spinner fa-spin" v-if="saving"></i>
                            Valider
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { required } from 'vuelidate/lib/validators'

    export default {
        props : ['currentOrder'],
        data() {
            return {
                saving: false,
                submitted: false,
                quote : {
                    id : this.currentOrder ? this.currentOrder.id : null,
                    title : this.currentOrder? this.currentOrder.title : null,
                    contact : this.currentOrder? this.currentOrder.contact : null,
                    address : this.currentOrder? this.currentOrder.address : null,
                    cpcity : this.currentOrder? this.currentOrder.cpcity : null,
                    vat : this.currentOrder? this.currentOrder.vat : null,
                    price : this.currentOrder? this.currentOrder.price : null,
                    manager : this.currentOrder? this.currentOrder.manager : null,
                    first_visit_date : this.currentOrder? this.currentOrder.first_visit_date : null,
                    user_id : this.currentOrder? this.currentOrder.user_id : this.user && this.user.id,
                    is_estimate : this.currentOrder? this.currentOrder.is_estimate : 1,
                    estimate_status_id : this.currentOrder? this.currentOrder.estimate_status_id : 1,
                }
            }
        },
        validations: {
            quote : {
                title : {required},
                vat : {required},
            },
        },
        computed: {
            user() {
                return this.$store.state.user.data;
            },
        },
        components : {
        },
        methods: {
            save() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.submitted = true
                    return
                }
                this.saving = true;
                this.$store.dispatch('storeOrder', this.quote).then(() => {
                    this.$store.commit('hideModal')
                    this.saving = false;
                }).catch((e) => {
                    this.saving = false;
                    alert(e.message)
                })
            }
        },
        mounted() {
        },
    }
</script>
