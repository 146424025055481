<template>
<div class="card">
    <div class="card-body row" v-if="loaded">
        <div class="col-lg-12" v-for="cat in categoriesId" :key="cat">
            <div class="row">
                <div class="col-lg-8">
                    <div class="form-group">
                        <label for="" class="small mb-0" :class="{ 'text-danger': submitted && $v.accessories.$each.$iter[cat].accessory_id.$error }">{{ accessoriesList.find(a => a.accessory_category_id == cat).accessory_category.name }}*</label>

                        <v-select class="style-chooser"
                                  label="name"
                                  :clearable="false"
                                  v-model="accessories[cat].accessory"
                                  @input="updateAccessory(cat, $event)"
                                  :options="accessoriesList.filter(a => a.accessory_category_id == cat)"
                                  :class="{'border border-danger': submitted && $v.accessories.$each.$iter[cat].accessory_id.$error }"
                        >
                            <span slot="no-options">Aucun accessoire disponible</span>
                        </v-select>
                        <span class="small" v-if="accessories[cat].accessory && accessories[cat].accessory.category && accessories[cat].accessory.accessory_category && accessories[cat].accessory.category.slug === 'fenetre-coulissante' && accessories[cat].accessory.accessory_category.slug === 'fermeture'">
                            Pour une hauteur inférieure à 1050 mm et selon la hauteur d'allège : 1 seul point de fermeture possible
                        </span>

                        <div class="text-danger small" v-if="submitted && $v.accessories.$each.$iter[cat].accessory_id.$error"><b>Accessoire obligatoire</b></div>
                    </div>
                </div>
                <div class="col-lg-4 text-right">
                    <a
                        target="_blank"
                        v-if="accessories[cat].accessory && accessories[cat].accessory.img_code && accessories[cat].accessory.img_code !== 'X'"
                        :href="`https://www.altech-ind.com/wp-content/uploads/${accessories[cat].accessory.code}.jpg`">
                        <img

                            :alt="`Image pour ${accessories[cat].accessory.name}`"
                            :src="`/images/accessories/${accessories[cat].accessory.img_code}.png`"
                            style="max-width: 100%; max-height:100px"
                        />
                    </a>
                    <img
                        v-else-if="accessories[cat].accessory && accessories[cat].accessory.img_code && accessories[cat].accessory.img_code.toUpperCase() === 'X'"
                        :alt="`Pas d'image pour ${accessories[cat].accessory.name}`"
                        :src="`/images/accessories/${accessories[cat].accessory.img_code.toUpperCase()}.png`"
                        style="max-width: 100%; max-height:100px"
                    />
                </div>
            </div>
        </div>
        <div v-if="!categoriesId.size" class="text-center w-100"><i class="small">Aucun accessoire disponible</i></div>
    </div>
</div>
</template>
<script>
    import { required } from 'vuelidate/lib/validators'

    export default {
        props : ['currentAccessoire'],
        data() {
            return {
                accessories : {},
                loaded : false,
                submitted : false,
            }
        },
        validations: {
            accessories: {
                $each: {
                    accessory_id : { required },
                }
            }
        },
        computed : {
            currentProduct () {
                return this.$store.state.orders.currentProduct
            },
            accessoriesList () { // ALL AVAILABLE ACCESSORIES FOR THIS PRODUCT CATEGORY
                let prodAcc = this.$store.state.accessories.items
                    .filter(a => a.accessory_category_id > 4)
                    .filter(a => !a.category_id || a.category_id === this.currentProduct.category_id || (this.currentProduct.category && this.currentProduct.category.parent_id === a.category_id))
                    .filter(a => a.vtl === null || a.vtl === this.currentProduct.vtl)

                if (this.currentProduct.category && this.currentProduct.category.slug === 'chassis-a-soufflet') {
                    prodAcc = prodAcc.filter(a => {
                        if (a.accessory_category && a.accessory_category.slug !== 'fermeture') {
                            return true;
                        }
                        if (this.currentProduct.label.toLowerCase().indexOf('loqueteau') >= 0) {
                            return a.name.toLowerCase().indexOf('loqueteau') >= 0;
                        } else if (this.currentProduct.label.toLowerCase().indexOf('crémone') >= 0) {
                            return a.name.toLowerCase().indexOf('crémone') >= 0;
                        } else {
                            return a.name.toLowerCase().indexOf('crémone') < 0 && a.name.toLowerCase().indexOf('loqueteau') < 0;
                        }
                    })
                }

                console.log('this.$store.state.orders.currentProduct',this.currentProduct);
                console.log('prodAcc', prodAcc);
                if (prodAcc) {
                    return prodAcc
                }
                return [];
            },
            categoriesId () { // TO SEPARATE ACCESSORIES TYPE
                const m = this.accessoriesList.map(a => a.accessory_category_id)
                console.log('m',m)
                return new Set([...m])
            },
            accessoriesToSave () {
                return Object.values(this.accessories)
            },
        },
        methods : {
            initAccessories () {
                this.accessories = {};
                this.categoriesId.forEach( cat => { // SET ACC IF CAT IF THE SAME / ELSE SET NEW ROW
                    this.accessories[cat] = this.currentAccessoire && this.currentAccessoire.find(a => this.accessoriesList.find(h => h.id === a.accessory_id && cat == h.accessory_category_id))
                    //console.log('accsory for cat id', cat, this.accessories[cat])
                    if (!this.accessories[cat]) {
                        // Find default accessory in this category
                        const catAcc = this.accessoriesList.filter(a => a.accessory_category_id === cat).find(a => a.default);

                        // console.log('category', this.accessoriesList.filter(a => a.accessory_category_id === cat)[0].accessory_category.name)
                        // console.log('cat acc', this.accessoriesList.filter(a => a.accessory_category_id === cat))
                        // console.log('catAcc', catAcc)
                        if (catAcc) {
                            this.accessories[cat] = {accessory: {...catAcc}, accessory_id : catAcc.id, quantity : 1}
                        } else {
                            this.accessories[cat] = {accessory_id : null, quantity : 1}
                        }
                    }
                })
            },
            updateAccessory(cat, accessory) {
                this.accessories = {...this.accessories, [cat]: {accessory: {...accessory}, accessory_id: (accessory ? accessory.id : null)}}
            },
            checkValidation () {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.submitted = true
                    return false
                } else {
                    return true
                }
            },
        },
        mounted () {
            this.initAccessories()
            this.loaded = true

        },
        watch : {
            currentAccessoire () { // TO UPDATE ACCESSORIES WHEN PRODUCT SWITCH
                this.initAccessories()
            },
        },
    }
</script>
