<template>
    <div class="col-md-4 my-3 p-3 text-center">
        <p class="small text-uppercase">Détails du compte</p>
        <div class="card text-left" style="min-height:80%;">
            <div class="card-body">
                <div class="row small" style="line-height: 2;">
                    <img v-if="user.account && user.account.logo_url" class="col-4 mb-3" :src="'../storage/' + user.account.logo_url" />
                    <h5 :class="user.account && user.account.logo_url ? 'col-8' : 'col-12'">
                        {{ user.account && user.account.name }}
                    </h5>
                    <div class="col-md-4">
                        <span class="small text-muted">No SIRET</span>
                    </div>
                    <div class="col-md-8">
                        {{ user.account && user.account.siret }}
                    </div>
                    <div class="col-md-4">
                        <span class="small text-muted">Contact</span>
                    </div>
                    <div class="col-md-8">
                        {{ user.firstname }} {{ user.lastname }}
                    </div>
                    <div class="col-md-4">
                        <span class="small text-muted">Mail</span>
                    </div>
                    <div class="col-md-8">
                        {{ user.email }}
                    </div>
                    <div class="col-md-4">
                        <span class="small text-muted">Téléphone</span>
                    </div>
                    <div class="col-md-8">
                        {{ user.phone }}
                    </div>
                    <div class="col-md-4">
                        <span class="small text-muted">Adresse</span>
                    </div>
                    <div class="col-md-8">
                        {{ user.account && user.account.address }}
                    </div>
                    <div class="col-md-4">
                        <span class="small text-muted">Coef de marge</span>
                    </div>
                    <div class="col-md-8">
                        {{ user.account && user.account.margin + " %" }}
                    </div>
                    <div class="col-md-4" v-if="user.role && user.role.slug === 'admin'">
                        <span class="small text-muted">Notifications</span>
                    </div>
                    <div class="col-md-8" v-if="user.role && user.role.slug === 'admin'">
                        <span v-if="user.account && user.account.notif_mail"><i class="fas fa-check text-success"></i></span>
                        <span v-else><i class="fas fa-times text-danger"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-info btn-sm" @click="$router.push('/compte')">Modifier son compte</button>

    </div>
</template>
<script>
    export default {
        name: 'HomeAccount',
        data() {
            return {

            }
        },
        computed : {
            user() {
                return this.$store.state.user.data;
            },
        },
    }
</script>
