<template>
<div class="col-md-4 my-3 p-3 text-center">
    <p class="small text-uppercase">Dernières commandes</p>
    <div class="card text-left" style="min-height:80%;">
        <div class="card-body d-flex px-0 py-1">
            <ul class="list-group list-group-flush" v-if="orders && orders.length">
                <li class="list-group-item" v-for="(order, i) in orders" :key="order.id" style="line-height: 1.1;" :class="[i % 2 ? 'bg-light' : '']">
                    <div class="row small">
                        <div class="col-lg-12 pb-2">
                            <!-- <span class="small text-muted">Date </span> <br /> -->
                            <b>Commande n°{{ order.id }}</b>
                        </div>
                        <div class="col-lg-7 pb-2">
                            <!-- <span class="small text-muted">Date </span> <br /> -->
                            {{ frenchDate(order.created_at) }}
                        </div>
                        <div class="col-lg-5 pb-2">
                            <!-- <span class="small text-muted">Montant </span> <br /> -->
                            {{ order.price && `${(order.price/100).toLocaleString('fr', { minimumFractionDigits: 2 })}  €` }}
                        </div>
                        <div class="col-lg-7">
                            <span class="small text-muted">Chantier </span> <br />
                            {{ order.title }}
                        </div>
                        <div class="col-lg-5">
                            <span class="small text-muted">Statut </span> <br />
                            {{ order.status && order.status.name }}
                        </div>
                    </div>
                </li>
            </ul>
            <div v-else class="small text-muted text-center m-auto">
                Aucune commande
            </div>
        </div>
    </div>
    <button type="button" class="btn btn-info btn-sm" @click="$router.push('/commandes')">Voir toutes les commandes</button>
    <!-- <div class="position-absolute" style="left: 50%; bottom:15px">
            <button type="button" class="btn btn-primary position-relative" style="left: -50%;min-width: 280px;">Voir toutes les commandes</button>
    </div> -->
</div>

</template>
<script>
    import {frenchDate} from '../../utils/dates'

    export default {
        name: 'HomeOrders',
        data() {
            return {
                frenchDate,
            }
        },
        computed : {
            orders() {
                return this.$store.state.orders.items && this.$store.state.orders.items
                .filter(o => !o.is_estimate)
                .sort((a,b) => new Date(b.created_at) - new Date(a.created_at))
                .slice(0, 3)
            },
        },
    }
</script>
