<template>
    <div class="col-lg-12">
        <div class="card m-0" style="background: transparent;box-shadow: none;">
            <div class="py-0">


                <div class="card bg-light mb-4">
                    <div class="card-header">Alerte à destination des utilisateurs</div>
                    <div class="card-body text-right" v-if="message">
                        <textarea class="form-control" v-model="message.name" rows="4"></textarea>
                        <button class="btn btn-info mt-2" @click="saveMessage">
                            <i class="fas fa-spin fa-spinner" v-if="saving"></i>
                            Enregistrer
                        </button>
                    </div>
                </div>


                <div class="row mt-3">
                    <div class="col-9 pt-4">
                        <button type="button" class="btn btn-outline-infox btn-sm text-info" @click="currentUser = null; $store.commit('showModal', 'users')"><i class="fas fa-plus"></i>  <span class="ml-1"> Ajouter un utilisateur</span> </button>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <div class="form-row">
                                <div class="states col">
                                    <span class="small font-weight-bold">Rechercher</span>
                                    <input class="form-control form-control-sm" type="text" v-model="search">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body bg-white">

                <table class="table table-borderedx table-striped bg-white" style="font-size: 15px;">
                    <thead class="text-uppercase">
                    <tr>
                        <th class="small border-top-0 p-1">Nom</th>
                        <th class="small border-top-0 p-1">Mail</th>
                        <th class="small border-top-0 p-1">Téléphone</th>
                        <th class="small border-top-0 p-1">Contact</th>
                        <th class="small border-top-0 p-1">Remise</th>
                        <th class="small border-top-0 p-1">Plus value</th>
                        <th class="small border-top-0 p-1">Nb Factures</th>
                        <th class="small border-top-0 p-1">Nb Devis</th>
                        <th class="small border-top-0 p-1">Total facturé</th>
                        <th class="small border-top-0 p-1">Actif</th>
                        <th class="small border-top-0 p-1"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in users" :key="item.id">
                        <td class="pr-1"> {{ item.account ? item.account.name : '-' }} </td>
                        <td class="px-1"> {{ item.email }} </td>
                        <td class="px-1"> {{ item.phone }} </td>
                        <td class="pr-1"> {{ item.firstname }} {{ item.lastname }} </td>
                        <td class="px-1"> {{ item.account && item.account.discount ? `${item.account.discount} %` : '0%' }}</td>
                        <td class="px-1"> {{ item.account && item.account.gain ? `${item.account.gain} %` : '0%' }}</td>
                        <td class="px-1">{{ item.orders.filter(o => !o.is_estimate && o.documents.find(d => d.type === 'bill')).length }}  </td>
                        <td class="px-1">{{ item.orders.filter(o => o.is_estimate).length }}</td>
                        <td class="px-1">{{ totalBill(item) }} €</td>
                        <td class="px-1"> 
                            <select class="form-control form-control-sm p-1" v-model="item.actif" @change="$store.dispatch('saveUser', {...item, ...item.account, id : item.id, logo : null })" style="min-width: 60px;font-size: 14px;">
                                <option :value="1">Oui</option>
                                <option :value="0">Non</option>
                            </select> 
                        </td>
                        <td class="px-1">
                            <button class="btn" @click="currentUser = item;$store.commit('showModal', 'users')">
                                <i class="fas fa-pen small"></i>
                            </button>
                            <!-- <button class="btn" @click="currentUser = item;$store.commit('showModal', 'users')">
                                <i class="fas fa-trash small"></i>
                            </button> -->
                        </td>
                    </tr>

                    <tr v-if="users && !users.length">
                        <td style="text-align: center" colspan="10">Aucun utilisateur ne correspond</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <UserModal :currentUser="currentUser" v-if="modal==='users'"></UserModal>
    </div>
</template>
<script>
    import UserModal from './UserModal.vue'
    export default {
        data() {
            return {
                search : '',
                currentUser : null,
                message : { id : null, name : null},
                saving: false,
            }
        },
        computed : {
            users: function () {
                return this.$store.state.users.items ? this.$store.state.users.items.filter( user => {
                    return this.search && user.account ? user.account.name.toUpperCase().includes(this.search.toUpperCase()) : true
                }) : []
            },
            modal() {
                return this.$store.state.modals.shown;
            },
            currentMessage () {
                return this.$store.state.messages.current
            }
        },
        mounted () {
            this.message = this.currentMessage ? this.currentMessage : { id : null, name : null}
        },
        methods : {
            totalBill (item) {
                let prices = []
                let ordersWithBill = item.orders.filter(o => !o.is_estimate && o.documents.find(d => d.type === 'bill'))
                ordersWithBill.forEach(o => o.order_lines.forEach(l => prices.push(l.order_price)))
                return prices.reduce((a, b) => a + b, 0) / 100
            },
            saveMessage() {
                this.saving = true;
                this.$store.dispatch('storeMessage', this.message).then(() => {
                    this.saving = false;
                }).catch((e) => {
                    alert('Le message n\'a pas été sauvegardé. Le message d\'erreur est : '+e.message)
                    this.saving = false;
                })
            }
        },
        components: {
            UserModal,
        },
        watch : {
            currentMessage () {
                this.message = this.currentMessage ? this.currentMessage : { id : null, name : null}
            }
        }
    }
</script>