import axios from 'axios'

export default {
    state: { items: null },
    mutations: {
        setFitTypes (state, items) {
            state.items = items;
        },
    },
    actions: {
        getFitTypes({ commit }) {
            const req = axios.get('/api/fit_types');

            req.then(r => {
                commit('setFitTypes', r.data);
            }).catch(err => {
                console.log('could not get fittypes', err)
            });
            return req;
        }
    }
};