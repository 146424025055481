<template>
<div class="card">
    <div class="card-body">
        <div class="row">
            <!-- INTERIEUR -->
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="" class="small mb-0">Montant HT</label>
                    <input min="0" type="number" class="form-control form-control-sm" v-model="ht_custom_price" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    export default {
        props : ['montant'],
        data() {
            return {
                ht_custom_price : this.montant ? this.montant : null,
            }
        },
        mounted () {
        },
    }
</script>
