<template>
    <div class="modal-backdrop" @click.prevent="$emit('close')">
        <div class="modal" tabindex="-1" role="dialog" style="display: block" >
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            {{ fitType.name }}
                        </h5>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="$emit('close')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <img class="card-img-top" :src="`/images/fitTypes/${fitType.slug}.png`"  style="object-fit: contain;"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props : ['fitType'],
    }
</script>
