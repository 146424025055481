<template>
<div id="home-devis" class="col-md-4 my-3 p-3 text-center">
    <p class="small text-uppercase">Derniers devis</p>
    <div class="card" style="min-height:80%;">
        <div class="card-body text-left d-flex px-0 py-1">
            <ul class="list-group list-group-flush" v-if="orders && orders.length">
                <li class="list-group-item " v-for="(order, i) in orders" :key="order.id" style="line-height: 1.1;" :class="[i % 2 ? 'bg-light' : '']">
                    <div class="row small">
                        <div class="col-lg-12 pb-2">
                            <!-- <span class="small text-muted">Date </span> <br /> -->
                            <b><router-link :to="`/devis/${order.id}`">Devis n°{{ order.id }}</router-link></b>
                        </div>
                        <div class="col-lg-7 pb-2">
                            <!-- <span class="small text-muted">Date </span> <br /> -->
                            {{ frenchDate(order.created_at) }}
                        </div>
                        <div class="col-lg-5 pb-2">
                            <!-- <span class="small text-muted">Montant </span> <br /> -->
                            {{ `${order.order_lines ? (order.order_lines.map(l => l.estimate_price).reduce((a, b) => a + parseInt(b), 0) / 100).toLocaleString('fr', { minimumFractionDigits: 2 }) : '0,00'} €` }}
                        </div>
                        <div class="col-lg-7">
                            <span class="small text-muted">Chantier </span> <br />
                            {{ order.title }}
                        </div>
                        <div class="col-lg-5">
                            <span class="small text-muted">Statut </span> <br />
                            {{ order.estimate_status && order.estimate_status.name }}
                        </div>
                    </div>
                </li>
            </ul>
            <div v-else class="small text-muted text-center m-auto">
                Aucun devis
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 p-0">
        <button type="button" class="btn btn-info btn-sm" @click="$store.commit('showModal', 'newEstimate')"><i class="fas fa-plus small"></i> Créer un devis</button>
        </div>
        <div class="col-6 p-0">
        <button type="button" class="btn btn-info btn-sm" @click="$router.push('/devis')">Voir tous les devis</button>
        </div>
    </div>
</div>
    
</template>
<script>
    import {frenchDate} from '../../utils/dates'
    export default {
        name: 'HomeEstimates',
        data() {
            return {
                frenchDate,
            }
        },
        mounted () {
        },  
        computed : {
            user() {
                return this.$store.state.user.data;
            },
            orders() {
                return this.$store.state.orders.items && this.estimateStatuses && this.$store.state.orders.items
                    .filter(o => o.is_estimate)
                    // .map(o => ({...o, estimate_status : this.estimateStatuses.find(s => s.id === o.estimate_status_id)})) // TO REFRESH IF UPDATED
                    .sort((a,b) => new Date(b.created_at) - new Date(a.created_at))
                    .slice(0, 3)
            },
            estimateStatuses () {
                return this.$store.state.estimateStatuses.items
            },
        },
        methods : {
        }
    }
</script>