<template>
    <div class="wrapper">
        <Menu v-if="user"></Menu>

        <div class="content-wrapper bg-light">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                
            }
        },
        mounted() {
            this.$store.dispatch('getUser').then(() => {
            }).catch(() => {
                this.$router.push('/login')
            })
        },
        computed : {
            user: function () {
                return this.$store.state.user.data
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout')
            }
        }
    }
</script>
<style>

</style>