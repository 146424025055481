import axios from 'axios'

export default {
    state: { items: null },
    mutations: {
        setColors (state, colors) {
            state.items = colors;
        },
    },
    actions: {
        getColors({ commit }) {
            const req = axios.get('/api/colors');

            req.then(r => {
                commit('setColors', r.data);
            }).catch(err => {
                console.log('could not get colors', err)
            });

            return req;
        }
    }
};