import axios from 'axios'

export default {
    state: { items: null },
    mutations: {
        setWood (state, items) {
            state.items = items;
        },
    },
    actions: {
        getWood({ commit }) {
            const req = axios.get('/api/wood');

            req.then(r => {
                commit('setWood', r.data);
            }).catch(err => {
                console.log('could not get wood', err)
            });

            return req;
        }
    }
};