<template>
    <div class="wrapper">
        <div class="content-wrapper bg-light">
            <div class="container">
                <div class="row pt-5 pb-5">
                    <div class="fields col-md-12 row">
                        <UserForm ref="userform" :currentUser="user" class="col-md-12"></UserForm>
                        <div class="col-md-6 text-left"><button type="button" class="btn btn-secondary" @click.prevent="$router.back()">Annuler</button></div>
                        <div class="col-md-6 text-right"><button type="button" class="btn btn-info" @click="save"><i class="fa fa-spinner fa-spin" v-if="saving"></i> Valider</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserForm from './UserForm.vue'
export default {
    name : 'Profile',
    data () {
        return {
            saving: false,
        }
    },
    computed: {
        user() {
            return this.$store.state.user.data;
        },
        modal() {
            return this.$store.state.modals.shown;
        }
    },
    methods: {
        async save() {
            this.saving = true;
            const res = await this.$refs.userform.save();
            this.saving = false;
            if (res) {
                this.$router.back()
            }

        }
    },
    components: {
        UserForm
    }

}
</script>

<style>

</style>