<template>
    <li class="mb-2">
        <div v-if="currentOrder && currentDocument">
            <span class="font-weight-bold">{{ currentDocument.file }}  </span>
            <button class="btn text-danger mb-1" @click="deleteDocument(type)" title="Supprimer">
                <i class="fas fa-trash small"></i>
            </button> 
        </div>
        <div class="custom-file" v-else>
            <input type="file" class="custom-file-input" :id="type" lang="fr" @change="setDocument(type, $event)">
            <label class="custom-file-label" :for="type">{{ (document_file && document_file.name ? document_file.name : '') }}</label>
        </div>
    </li>

</template>
<script>
    import axios from 'axios'

    export default {
        name: 'ManageDocument',
        props: ['currentOrder', 'type', 'document_file'],
        data() {
            return {
                currentDocument : null,
            }
        },
        watch: {
        },
        computed: {
        },
        mounted () {
            this.currentDocument = this.currentOrder ? this.currentOrder.documents.find(d => d.type === this.type) : null
        },
        methods: {
            setDocument(type, ev) {
                if (ev.target.files.length) {
                    // this[type] =  ev.target.files[0]
                    this.$emit('setDocumentValue', {type : type, value : ev.target.files[0]})
                }
            },
            deleteDocument (type) {
                if (this.currentDocument && confirm(`Souhaitez-vous supprimer définitivement le fichier ${this.currentDocument.file} ?`)) {
                    axios.delete(`/api/orders/${this.currentOrder.id}/documents/${this.currentDocument.id}`)
                    .then(res => {
                        this.$store.dispatch('storeOrder', {...this.currentOrder, documents : this.currentOrder.documents.filter(d => d.id !== this.currentDocument.id)})
                        this.currentDocument = null
                    })
                }
                this.$emit('setDocumentValue', {type : type, value : null})
            },
        },
    }
</script>