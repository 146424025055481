<template>
<div class="card">
    <div class="card-body">
        <div class="row">
            <!-- INTERIEUR -->
            <div class="col-lg-6" v-if="hasInt">
                <div class="form-group">
                    <label for="" class="small mb-0">Habillages intérieurs</label>

                    <v-select class="style-chooser"
                        v-model="interieur.accessory_id"
                        label="name"
                        :options="interieurs"
                        :reduce="item => item.id" >
                        <span slot="no-options">Aucun intérieur disponible</span>
                    </v-select>
                </div>
            </div>
            <!-- EXTERIEUR -->
            <div class="col-lg-6" v-if="hasExt">
                <div class="form-group">
                    <label for="" class="small mb-0">Habillages extérieurs / Tapées</label>
                    <v-select class="style-chooser"
                        v-model="exterieur.accessory_id"
                        label="name"
                        :options="exterieurs"
                        :reduce="item => item.id" >
                        <span slot="no-options">Aucun extérieur disponible</span>
                    </v-select>
                </div>
            </div>
            <!-- APPUI -->
            <div class="col-lg-6" v-if="hasApp">
                <div class="form-group">
                    <label for="" class="small mb-0">Appuis</label>
                    <v-select class="style-chooser"
                        v-model="appui.accessory_id"
                        label="name"
                        :options="appuis"
                        :reduce="item => item.id" >
                        <span slot="no-options">Aucun extérieur disponible</span>
                    </v-select>
                </div>
            </div>
            <div v-if="!hasApp && !hasExt && ! hasInt" class="text-center w-100 col-lg-12">
                <i class="small">Aucun habillage disponible</i>
            </div>

        </div>
    </div>
</div>
</template>
<script>
    export default {
        props : ['currentAccessoire', 'product'],
        data() {
            return {
                interieur : {accessory_id : null, quantity : 1},
                exterieur : {accessory_id : null, quantity : 1},
                appui : {accessory_id : null, quantity : 1},
            }
        },
        computed: {
            accessories () {
                let a = []
                a.push(this.interieur, this.exterieur, this.appui)
                return a.filter(a => a.accessory_id)
            },
            fitType () {
                return this.$store.state.fitTypes.items.find(t => t.id === this.product.line.fit_type_id)
            },
            interieurs () {
                return this.$store.state.accessories.items ? this.$store.state.accessories.items.filter(a => a.accessory_category_id === 1 && (!a.category_id || a.category_id === this.product.category_id || a.category_id === this.product.category.parent_id)).filter(this.checkFitType) : []
            },
            exterieurs () {
                return this.$store.state.accessories.items ? this.$store.state.accessories.items.filter(a => (a.accessory_category_id === 2 || a.accessory_category_id === 3) && (!a.category_id || a.category_id === this.product.category_id || a.category_id === this.product.category.parent_id)).filter(this.checkFitType) : []
            },
            appuis () {
                if (this.product.sans_appuis) {
                    return null;
                }
                return this.$store.state.accessories.items ? this.$store.state.accessories.items.filter(a => a.accessory_category_id === 4 && (!a.category_id || a.category_id === this.product.category_id || (this.product.category && a.category_id === this.product.category.parent_id ))).filter(this.checkFitType) : []
            },
            hasExt() {
                return this.exterieurs && this.exterieurs.length && (!this.product.line || !this.product.line.fit_type || (this.product.line.fit_type.slug !== 'tunnel' && this.product.line.fit_type.slug !== 'feuillure' && this.product.line.fit_type.slug !== 'applique'))
            },
            hasInt(){
                return this.interieurs && this.interieurs.length && (!this.product.line || !this.product.line.fit_type || this.product.line.fit_type.slug !== 'tunnel')
            },
            hasApp() {
                return this.appuis && this.appuis.length
            }
        },
        mounted () {
            this.interieur.accessory_id = this.findCurrentAcc(this.interieurs).id
            this.exterieur.accessory_id = this.findCurrentAcc(this.exterieurs).id
            this.appui.accessory_id = this.findCurrentAcc(this.appuis).id
        },
        methods : {
            findCurrentAcc (type) {
                return type && this.currentAccessoire && type.find(i => this.currentAccessoire.find(h => h.accessory_id === i.id)) ? type.find(i => this.currentAccessoire.find(h => h.accessory_id === i.id)) : {id : null}
            },
            checkFitType (accessory) {
                if (!accessory.fit_type || accessory.fit_type.length === 0) {
                    return true;
                }
                let ids = accessory.fit_type.map(type => type.id)
                return ids.includes(this.fitType.id)
            },
        },
        watch : {
            currentAccessoire () {
                // UPDATE HABILLAGE ON SWITCH PRODUCT
                this.interieur.accessory_id = this.findCurrentAcc(this.interieurs).id
                this.exterieur.accessory_id = this.findCurrentAcc(this.exterieurs).id
                this.appui.accessory_id = this.findCurrentAcc(this.appuis).id
            }
        },
    }
</script>
