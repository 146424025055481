<template>
    <div class="wrapper">
        <div class="content-wrapper bg-light">
            <div class="container">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name : 'Orders',
    data () {
        return {

        }
    },
    computed : {
        user: function () {
            return this.$store.state.user.data;
        },
    },
    mounted () {
    }, 
    methods : {

    },
    components: {
    }

}
</script>

<style>

</style>