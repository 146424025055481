<template>
    <div class="wrapper">
        <div class="content-wrapper bg-light">
            <div class="container">
                <div class="row pt-5 pb-5">
                    <div class="fields col-md-12">
                        <div class="row">
                            <List></List>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import List from './List.vue'
export default {
    name : 'Utilisateurs',
    data () {
        return {

        }
    },
    methods : {

    },
    components: {
        List
    }

}
</script>

<style>

</style>