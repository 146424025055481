<template>
    <div class="modal-backdrop">
        <div class="modal" tabindex="-1" role="dialog" style="display: block" >
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modal_edit_incident">
                            <b> {{currentUser ? `Modification du compte ${currentUser.account ? currentUser.account.name : ''}` : 'Nouveau compte'}} </b>
                        </h5>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="$store.commit('hideModal')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <UserForm :currentUser="editingUser" ref="edituserform"></UserForm>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn link" v-on:click="$store.commit('hideModal')">Annuler</button>
                        <button type="button" class="btn btn-info" @click="save"><i class="fa fa-spinner fa-spin" v-if="saving"></i> Valider</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import UserForm from './UserForm.vue'

    export default {
        props: ['currentUser'],
        data() {
            return {
                saving: false,
                editingUser: this.currentUser,
            }
        },

        watch: {
            currentUser() {
                this.editingUser = this.currentUser;
            }
        },
        components : {
            UserForm,
        },

        methods: {
            async save() {
                console.log('saving user', this.$refs.edituserform.save)
                this.saving = true;
                const res = await this.$refs.edituserform.save();
                this.saving = false;
                if (res) {
                    this.$store.commit('hideModal');
                }
            }
        },

        mounted() {
        },
    }
</script>
