export default {
    state: { errors: null },
    mutations: {
        setErrors (state, errors) {
            state.errors = errors;
        },
        clearErrors (state) {
            state.errors = null;
        }
    },
};
