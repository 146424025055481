<template>
    <div>
        <div class="row segment" :id="'segment-'+ index">
            <div :class="'form-group ' + (fill && fill_type && fill_type.slug === 'panneau-porte' ? 'col-lg-2' : 'col-lg-3')" v-if="index < nparts-1">
                <label class="small mb-0 font-weight-normal" style="font-size: 12px;" :class="{ 'text-danger': $v.height.$error }">Hauteur segment*</label>
                <input type="number" min="0" @change="heightChanged" class="form-control form-control-sm" v-model="height" :class="{'is-invalid': $v.height.$error }" />
                <div class="text-danger small" v-if="$v.height.$error"><b>Hauteur obligatoire</b></div>
            </div>
            <div :class="'form-group ' + (fill && fill_type && fill_type.slug === 'panneau-porte' ? 'col-lg-2' : 'col-lg-3')" v-else>
                <label class="small mb-0 font-weight-normal" style="font-size: 12px;">Hauteur segment</label>
                <span v-if="lsh">{{ lsh }}</span>
                <span v-else>Inconnue</span>
            </div>
            <div class="form-group col-lg-4">
                <label class="small mb-0 font-weight-normal" style="font-size: 12px;" :class="{ 'text-danger': $v.fill_type.$error }">Type remplissage*</label>

                <v-select :clearable="false" class="style-chooser"
                          v-model="fill_type"
                          label="name"
                          @input="fillTypeChanged"
                          :options="fillsType"
                          :class="{'is-invalid': $v.fill_type.$error }">
                    <span slot="no-options">Aucun type disponible</span>
                </v-select>

                <div class="text-danger small" v-if="$v.fill_type.$error"><b>Type obligatoire</b></div>
            </div>
            <div class="form-group col-lg-5">
                <label class="small mb-0 font-weight-normal" style="font-size: 12px;" :class="{ 'text-danger': $v.fill.$error }">Remplissage*</label>

                <v-select class="style-chooser"
                          :clearable="false"
                          v-model="fill"
                          label="name"
                          :options="fills"
                          :disabled="!fill_type"
                          :style="!fill_type && 'background-color: #e9ecef'"
                          :class="{'is-invalid': $v.fill.$error }">
                    <span slot="no-options">Aucun remplissage disponible</span>
                </v-select>

                <div class="text-danger small" v-if="$v.fill.$error"><b>Remplissage obligatoire</b></div>
            </div>
            <div class="form-group col-lg-1">
                <a href="#" @click.prevent="doorModal=true">
                    <img style="max-width: 100%;margin-top: 1.5em" :alt="`image pour ${fill.name}`" :src="`/images/portes/${fill.code}.png`" v-if="fill && fill_type.slug === 'panneau-porte'" />
                </a>
                <DoorFillModal :title="fill.name" :image="`/images/portes/${fill.code}.png`" v-if="doorModal" @close="doorModal=false"></DoorFillModal>

            </div>
        </div>
        <div class="row" v-if="woods">
            <div class="form-group col-lg-3">
                <label for="woodhor" class="small mb-0 font-weight-normal" :class="{ 'text-danger': $v.wood_hor.$error }" style="font-size: 12px;">Petit bois horiz</label>
                <input id="woodhor" type="number" min="0"  v-model="wood_hor"  class="form-control form-control-sm"  :class="{ 'is-invalid': $v.wood_hor.$error }" />
                <div class="text-danger small" v-if="$v.wood_hor.$error"><b>Erreur sur le nombre de petits bois horizontaux</b></div>
            </div>
            <div class="form-group col-lg-3">
                <label for="woodvert" class="small mb-0 font-weight-normal" :class="{ 'text-danger': $v.wood_vert.$error }"  style="font-size: 12px;">Petit bois vert</label>
                <input id="woodvert" type="number" min="0" class="form-control form-control-sm" v-model="wood_vert"  :class="{ 'is-invalid': $v.wood_hor.$error }" />
                <div class="text-danger small" v-if="$v.wood_vert.$error"><b>Erreur sur le nombre de petits bois verticaux</b></div>
            </div>

            <div class="form-group col-lg-6" v-if="(wood_hor > 0 || wood_vert > 0) && woods">
                <label class="small mb-0 font-weight-normal" style="font-size: 12px;" :class="{ 'text-danger': $v.wood.$error }">Type de petits bois*</label>
                <v-select class="style-chooser"
                          :clearable="false"
                          v-model="wood"
                          label="name"
                          :options="woods"
                          :disabled="!fill"
                          :style="!fill && 'background-color: #e9ecef'"
                          :class="{'is-invalid': $v.wood.$error }">
                    <span slot="no-options">Aucun petits bois disponible</span>
                </v-select>
                <div class="text-danger small" v-if="$v.wood.$error"><b>Type des petits bois obligatoire</b></div>

            </div>
        </div>
        <div class="row" v-if="(index === nparts-1)&&(this.product.category.name === 'CHASSIS FIXE')">
            <div class="col-lg-1">
                <input type="checkbox" v-model="shape" :id="'segment-shape-'+ index" />
            </div>
            <div class="col-lg-11">
                <label :for="'segment-shape-'+ index" style="font-weight: normal">
                    Forme triangulaire / trapézoïdale
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import { required, requiredIf } from 'vuelidate/lib/validators'
    import DoorFillModal from "./DoorFillModal";

    export default {
        components: {DoorFillModal},
        props: ['segment', 'product', 'index', 'nparts', 'lsh', 'traverses'],

        data() {
            return {
                doorModal: false,
                fill_type: this.segment && this.segment.fill_type_id ? this.$store.state.fills.items.find(f => f.id === this.segment.fill_type_id) : null,
                fill: this.segment ? this.segment.part_fill : null,
                height: this.segment && this.index < this.nparts-1 ? this.segment.height : 0, //parseInt(this.lsh, 10),
                wood_vert: this.segment ? this.segment.wood_vert : null,
                wood_hor: this.segment ? this.segment.wood_hor : null,
                wood: this.segment ? this.segment.wood : null,
                submitted: false,
                shape: false,
            }
        },

        validations: {
            fill_type : { required },
            fill: { required },
            shape: {  },
            height: {
                required: requiredIf(function(value) {
                    return this.index && this.index > 0
                })
            },
            wood: {
                required: requiredIf(function(value) {
                    return value.wood_hor > 0 || value.wood_vert > 0
                }),
            },
            wood_hor: {
                minimumv: (v) => parseInt(v, 10) >= 0,
            },
            wood_vert: {
                minimumv: (v) => parseInt(v, 10) >= 0,
            }
        },
        methods: {
            checkValidation () {
                this.$v.$touch()
                console.log(this.$v)
                return !this.$v.$invalid
            },
            setHeight(h) {
                this.height = h;
            },
            fillTypeChanged() {
                this.fill = null;
            },
            heightChanged(e) {
                this.segment.height = e.target.value;
            }
        },
        watch: {
            segment(val) {
                this.fill_type = val && val.fill_type_id ? this.$store.state.fills.items.find(f => f.id === val.fill_type_id) : null;
                this.fill = val && val.fill ? val.fill : (val.fill_id ? this.fills.find(f => f.id === val.fill_id) : null);
                this.height = val ? val.height : null;
                this.wood_vert = val ? val.wood_vert : null;
                this.wood_hor = val ? val.wood_hor : null;
                this.wood = val ? val.wood : null;
                this.shape = val ? val.shape : false;
            }
        },
        computed: {
            fillsType () {
                const productAuthorizedFillsIds = this.product.authorized_fills.map(af => af.fill_id)
                return this.$store.state.fills.items
                    .map(f => {
                        const r = {...f}
                        r.fills = f.fills.filter(fi => {
                            return productAuthorizedFillsIds.indexOf(fi.id) >= 0
                        })

                        return r;
                    })
                    .filter(f => {
                        // Remove panneau de porte if traverses > 0
                        if (f.slug === 'panneau-porte' && this.traverses > 0) {
                            return false;
                        }
                        // REMOVE TYPE WITH NO FILLS
                        return f.fills.length
                    })

            },
            woods() {
                return this.fill && this.fill.pbi ? this.$store.state.wood.items : null;
            },
            fills() {
                if (this.fill_type && this.fillsType.find(t => t.id === this.fill_type.id)) {
                    return  this.fillsType.find(t => t.id === this.fill_type.id).fills.filter(f => {
                        if (this.product && this.product.line) {
                            if (this.index === this.nparts-1) {
                                this.height = parseInt(this.lsh, 10);
                            }

                            const area = (parseInt(this.height, 10) - this.product.ded_filling_height) * (parseInt(this.product.line.width, 10) - this.product.ded_filling_width) / 1000000 / (this.product && this.product.vtl ? this.product.vtl : 1);
                            if (f.max_area){
                                return area < parseFloat(f.max_area);
                            }
                        }
                        return true;
                    })
                }
                this.fillTypeChanged();
                this.fill_type = null;
                return []
            },
            fill_id() {
                return this.fill ? this.fill.id : null;
            },
            fill_type_id() {
                return this.fill_type ? this.fill_type.id : null;
            },
            wood_id() {
                return this.wood ? this.wood.id : null;
            }
        }
    }
</script>
