require('./bootstrap');

import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import Vuelidate from 'vuelidate';
import vSelect from 'vue-select';

import routes from './routes'

import stores from './stores'

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.component('v-select', vSelect);

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

export const router = new VueRouter({
    routes,
    mode: 'history',
});

const store = new Vuex.Store(stores);

new Vue({
    el: '#app',
    store: store,
    router,
}).$mount('#app');