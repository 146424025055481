<template>
    <div class="progress" style="background-color: rgba(0, 0, 0, 0.25);">
        <div :class="color" role="progressbar" :style="'width:'+percentage+'%;'" :aria-valuenow="percentage" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
</template>
<script>
export default({
   name:'ProgressBar',
   props: ['status'],
   data() {
        return {
            title : null,
            status_name : null,
            percentage:null,
            color:null,
            test:null,
        }
    },
    computed: {
    },
    mounted(){
        this.setStatusName();
        this.setPercentageNColor();
    },
    watch:{
        status(){
            this.setStatusName();
            this.setPercentageNColor();
        }
    },
    methods:{
        setStatusName(){
            console.log(this.status)
            this.statusName = this.status.slug;
            console.log(this.status.slug)
        },
        setPercentageNColor(){
            if(this.statusName == 'commande-deposee'){
                this.percentage = 20;
                this.color = "progress-bar progress-bar-striped progress-bar-animated bg-warning";
            }else if(this.statusName == 'en-traitement'){
                this.percentage = 40;
                this.color = "progress-bar progress-bar-striped progress-bar-animated bg-info";
            }else if(this.statusName == 'en-production'){
                this.percentage = 60;
                this.color = "progress-bar progress-bar-striped progress-bar-animated bg-info";
            }else if(this.statusName == 'disponible'){
                this.percentage = 80;
                this.color = "progress-bar progress-bar-striped progress-bar-animated bg-success";
            }else if(this.statusName == 'livre'){
                this.percentage = 100;
                this.color = "progress-bar progress-bar-striped  bg-success";
            }else if(this.statusName == 'stand-by'){
                this.percentage = 100;
                this.color = "progress-bar progress-bar-striped bg-warning";
            }else if(this.statusName == 'annule'){
                this.percentage = 100;
                this.color = "progress-bar progress-bar-striped  bg-danger";
            }
        },
    },
})
</script>
