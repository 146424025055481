<template>
    <div>
        <!-- <div class="mb-3"><a href="#" class="btn btn-tool" v-on:click.prevent="$router.back()"><i class="fas fa-chevron-left"></i> Retour</a></div> -->

        <form @submit.prevent="save">
            <div class="row p-4">
                <div :class="((currentUser && currentUser.role && currentUser.role.slug==='client') || (roles && role_id === roles.find(r => r.slug === 'client').id) ? ' col-lg-6 border-right' :  'col-lg-12') +' mb-3'">
                    <h6 class="mb-4 font-weight-bold"> Compte Utilisateur </h6>
                    <ul class="list-unstyled pb-0 mb-0">
                        <li class="mb-2 row">
                            <div class="col-md-4" :class="{ 'text-danger': submitted && $v.firstname.$error }">Prénom* : </div>
                            <div class="col-md-6">
                                <input id="firstname" v-model="firstname" type="text" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && $v.firstname.$error }"/>
                                <div class="text-danger small" v-if="$v.firstname.$error"><b>Prénom obligatoire</b></div>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-4" :class="{ 'text-danger': submitted && $v.lastname.$error }">Nom* : </div>
                            <div class="col-md-6">
                                <input id="lastname" v-model="lastname" type="text" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && $v.lastname.$error }"/>
                                <div class="text-danger small" v-if="$v.lastname.$error"><b>Nom obligatoire</b></div>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-4">Téléphone : </div>
                            <div class="col-md-6">
                                <input id="phone" v-model="phone" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-4" :class="{ 'text-danger': submitted && $v.email.$error }">Email* : </div>
                            <div class="col-md-6">
                                <input id="email" v-model="email" type="email" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && $v.email.$error }"/>
                                <div class="text-danger small" v-if="!$v.email.required"><b>Email obligatoire</b></div>
                                <div class="text-danger small" v-if="!$v.email.email"><b>Le format de l'email n'est pas conforme</b></div>
                            </div>
                        </li>
                        <li class="mb-2 row" v-if="user && user.role && user.role.slug === 'admin'">
                            <div class="col-md-4" :class="{ 'text-danger': submitted && $v.role_id.$error }">Rôle* : </div>
                            <div class="col-md-6">
                                <select class="form-control form-control-sm" v-model="role_id" :class="{ 'is-invalid': submitted && $v.role_id.$error }">
                                    <option v-for="role in this.roles" :value="role.id" :key="role.id">
                                        {{ role.name }}
                                    </option>
                                </select>
                                <div class="text-danger small" v-if="$v.role_id.$error"><b>Rôle obligatoire</b></div>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-4" :class="{ 'text-danger': submitted && $v.password.$error }">Mot de passe* : </div>
                            <div class="col-md-6">
                                <input type="password" class="form-control form-control-sm" id="password" v-model="password" :class="{ 'is-invalid': submitted && $v.password.$error }">
                                <div class='text-danger small' v-if=" submitted && !$v.password.required"><b>Mot de passe obligatoire</b></div>
                                <div class='text-danger small' v-if="submitted && !$v.password.minLength"><b>Le mot de passe doit avoir au minimum {{ $v.password.$params.minLength.min }} caractères</b></div>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-4" :class="{ 'text-danger': submitted && $v.password_confirm.$error }">Confirmation mot de passe* : </div>
                            <div class="col-md-6">
                                <input type="password" class="form-control form-control-sm" id="password_confirm" v-model="password_confirm" :class="{ 'is-invalid': submitted && $v.password_confirm.$error }">
                                <div class='text-danger small' v-if="submitted && !$v.password_confirm.sameAsPassword"><b>Les mots de passe doivent être identiques</b></div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mb-3 col-lg-6 pl-3" v-if="(currentUser && currentUser.role && currentUser.role.slug==='client') || (roles && role_id === roles.find(r => r.slug === 'client').id)">
                    <h6 class="mb-4 font-weight-bold"> Compte Client </h6>
                    <ul class="list-unstyled pb-0 mb-0">
                        <li class="mb-2 row">
                            <div class="col-md-6" :class="{ 'text-danger': submitted && $v.account.name.$error }">Nom* : </div>
                            <div class="col-md-6">
                                <input id="name" v-model="account.name" type="text" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && $v.account.name.$error }"/>
                                <div class="text-danger small" v-if="$v.account.name.$error"><b>Nom obligatoire</b></div>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-6" :class="{ 'text-danger': submitted && !$v.account.logo.fileExtension }">Logo : </div>
                            <div class="col-md-6">
                                <img v-if="currentUser && currentUser.account.logo_url" class="my-2" :src="'../storage/' + currentUser.account.logo_url" style="max-height:50px; max-width: 200px"/>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="logo" lang="fr" @change="setDocument($event)" :class="{ 'is-invalid': submitted && !$v.account.logo.fileExtension }">
                                    <label class="custom-file-label" for="logo">{{ (account.logo && account.logo.name ? account.logo.name : account.logo ? account.logo : '') }}</label>
                                    <div class="text-danger" v-if="submitted && !$v.account.logo.fileExtension"><b>Extension invalide</b></div>

                                </div>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-6">SIRET : </div>
                            <div class="col-md-6">
                                <input id="siret" v-model="account.siret" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-6">Capital social : </div>
                            <div class="col-md-6">
                                <input id="capital" v-model="account.capital" type="number" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-6">Adresse : </div>
                            <div class="col-md-6">
                                <input id="address" v-model="account.address" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row" v-if="user && user.role && user.role.slug === 'admin'">
                            <div class="col-md-6">Coef de remise (%) : </div>
                            <div class="col-md-6">
                                <input id="discount" v-model="account.discount" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row" v-if="user && user.role && user.role.slug === 'admin'">
                            <div class="col-md-6">Coef de plus value (%) : </div>
                            <div class="col-md-6">
                                <input id="gain" v-model="account.gain" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row" v-if="user && user.role && currentUser && currentUser.id === user.id && user.role.slug === 'client'">
                            <div class="col-md-6">Coef de marge (%) : </div>
                            <div class="col-md-6">
                                <input id="margin" v-model="account.margin" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-6">N° de TVA Intra : </div>
                            <div class="col-md-6">
                                <input id="tva" v-model="account.tva" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-6">N° Décennale : </div>
                            <div class="col-md-6">
                                <input id="decennale" v-model="account.decennale" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-6">N° RC : </div>
                            <div class="col-md-6">
                                <input id="rc" v-model="account.rc" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-6">N° RGE : </div>
                            <div class="col-md-6">
                                <input id="rge" v-model="account.rge" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-6">Durée de validité des devis : </div>
                            <div class="col-md-6">
                                <input id="offer_valid" v-model="account.offer_valid" type="text" class="form-control form-control-sm"/>
                            </div>
                        </li>
                        <li class="mb-2 row" v-if="user.role && user.role.slug === 'admin'">
                            <div class="col-md-6">Notifications mail : </div>
                            <div class="col-md-6">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="account.notif_mail">
                                    <label class="custom-control-label" for="customSwitch1"></label>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
    import { required, email, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'
    const requiredIfNew = requiredIf(function () { return !this.currentUser })
    const requiredIfClient = requiredIf(function () { return this.role_id === this.roles.find(r => r.slug === 'client').id })
    export default {
        name: 'UserForm',
        props: ['currentUser'],
        data() {
            return {

                // STATE
                submitted: false,

                // USER
                actif : this.currentUser ? this.currentUser.actif : 1,
                firstname : this.currentUser ? this.currentUser.firstname : null,
                lastname : this.currentUser ? this.currentUser.lastname : null,
                phone : this.currentUser ? this.currentUser.phone : null,
                email : this.currentUser ? this.currentUser.email : null,
                role_id : this.currentUser ? this.currentUser.role_id : null,
                password : null,
                password_confirm : null,
                // ACOUNT
                account : {
                    name : this.currentUser && this.currentUser.account ? this.currentUser.account.name : null,
                    logo : this.currentUser && this.currentUser.account ? this.currentUser.account.logo : null,
                    siret : this.currentUser && this.currentUser.account ? this.currentUser.account.siret : null,
                    address : this.currentUser && this.currentUser.account ? this.currentUser.account.address : null,
                    discount : this.currentUser && this.currentUser.account ? this.currentUser.account.discount : null,
                    gain : this.currentUser && this.currentUser.account ? this.currentUser.account.gain : null,
                    margin : this.currentUser && this.currentUser.account ? this.currentUser.account.margin : null,
                    tva : this.currentUser && this.currentUser.account ? this.currentUser.account.tva : null,
                    decennale : this.currentUser && this.currentUser.account ? this.currentUser.account.decennale : null,
                    rc : this.currentUser && this.currentUser.account ? this.currentUser.account.rc : null,
                    rge : this.currentUser && this.currentUser.account ? this.currentUser.account.rge : null,
                    notif_mail : this.currentUser && this.currentUser.account ? this.currentUser.account.notif_mail : null,
                    capital : this.currentUser && this.currentUser.account ? this.currentUser.account.capital : null,
                    offer_valid : this.currentUser && this.currentUser.account ? this.currentUser.account.offer_valid : null,

                }

            }
        },
        watch: {
            currentUser() {
                // USER
                this.actif = this.currentUser ? this.currentUser.actif : 1
                this.firstname = this.currentUser ? this.currentUser.firstname : null;
                this.lastname = this.currentUser ? this.currentUser.lastname : null;
                this.phone = this.currentUser ? this.currentUser.phone : null;
                this.email = this.currentUser ? this.currentUser.email : null;
                this.role_id = this.currentUser ? this.currentUser.role_id : null;

                // ACOUNT
                this.account.name = this.currentUser && this.currentUser.account ? this.currentUser.account.name : null;
                this.account.logo = this.currentUser && this.currentUser.account ? this.currentUser.account.logo : null;
                this.account.siret = this.currentUser && this.currentUser.account ? this.currentUser.account.siret : null;
                this.account.address = this.currentUser && this.currentUser.account ? this.currentUser.account.address : null;
                this.account.discount = this.currentUser && this.currentUser.account ? this.currentUser.account.discount : null;
                this.account.margin = this.currentUser && this.currentUser.account ? this.currentUser.account.margin : null;
                this.account.tva = this.currentUser && this.currentUser.account ? this.currentUser.account.tva : null;
                this.account.decennale = this.currentUser && this.currentUser.account ? this.currentUser.account.decennale : null;
                this.account.rc = this.currentUser && this.currentUser.account ? this.currentUser.account.rc : null;
                this.account.rge = this.currentUser && this.currentUser.account ? this.currentUser.account.rge : null;
                this.account.notif_mail = this.currentUser && this.currentUser.account ? this.currentUser.account.notif_mail : null;
                this.account.capital = this.currentUser && this.currentUser.account ? this.currentUser.account.capital : null;
                this.account.offer_valid = this.currentUser && this.currentUser.account ? this.currentUser.account.offer_valid : null;
            }
        },
        validations: {
            firstname: { required },
            lastname: { required },
            email: { required, email },
            role_id: { required },
            password: {
                required : requiredIfNew,
                minLength: minLength(8)
            },
            password_confirm: {
                sameAsPassword: sameAs('password')
            },
            account : {
                name: {
                    required : requiredIfClient
                },
                logo : {
                    fileExtension () { return this.account.logo && this.account.logo.type ? ['image/png', 'image/jpeg'].includes(this.account.logo.type) : true }
                }
            }
        },
        computed: {
            roles () {
                return this.$store.state.roles.items;
            },
            user() {
                return this.$store.state.user.data;
            }
        },
        methods: {
            async save () {
                this.submitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    return false;
                } else {
                    let data = new FormData();
                    data.append('actif', this.actif)
                    data.append('firstname', this.firstname)
                    data.append('lastname', this.lastname)
                    this.phone && data.append('phone', this.phone)
                    data.append('email', this.email)
                    data.append('role_id', this.role_id)
                    console.log('this.account', this.account, this.account.id)
                    this.account.notif_mail = this.account.notif_mail ? 1 : 0;

                    Object.keys(this.account).forEach(key => this.account[key] && data.append(key, this.account[key]))

                    if (this.account.logo && !this.account.logo.type) data.delete('logo');
                    if(this.password) data.append('password', this.password); //userToSave.password = this.password

                    if (this.currentUser && this.currentUser.id) {
                        console.log(this.currentUser.id, this.currentUser)
                        data.append('id', this.currentUser.id)
                    }

                    await this.$store.dispatch('saveUser', data)

                    return true;
                }

            },
            setDocument(ev) {
                if (ev.target.files.length) {
                    this.account.logo = ev.target.files[0]
                }
            },
        },
    }
</script>
