<template>
<div class="card">
    <div class="card-body">

        <div class="row mb-2">
            <div class="col-lg-12">
                <small>
                    La sélection des tapées doit exclure&nbsp;:<br>
                    Pour les châssis coulissants; les tapées de 100 mm et les tapées hautes<br>
                    Pour les autres châssis; les tapées hautes
                </small>
            </div>
        </div>
        <div class="row">
            <!-- TYPE MANOEUVRE -->
            <div class="col-lg-6">
                <div class="form-group">
                    <label for="vr_type" class="small mb-0">Types de manœuvre</label>
                    <select class="form-control form-control-sm" v-model="vr_type" id="vr_type">
                        <option v-for="item in typesList" :key="item.id" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" v-model="vr_grill" id="grille">
                    <label class="form-check-label small font-weight-bold" for="grille">Grille Mortaise</label>
                </div>
                <div class="font-weight-bold mb-3" v-if="fitType && fitType.slug === 'neuf'">
                    Coffre derrière linteau
                </div>
                <div class="font-weight-bold mb-3" v-if="fitType && fitType.slug === 'renovation'">
                    Coffre compris dans la hauteur du tableau
                </div>
            </div>
            <!-- POSITION MANOEUVRE -->
            <div class="col-lg-6">
                <div class="form-group">
                    <label for="vr_pos" class="small mb-0">Position de la manœuvre</label>
                    <select id="vr_pos" class="form-control form-control-sm" v-model="vr_pos">
                        <option>Droite</option>
                        <option>Gauche</option>
                    </select>
                </div>
                <div class="form-group mb-2 row">
                    <label for="" class="small mb-0 col-7">Couleur coffre</label>
                    <div class="form-control form-control-sm col-5"
                    :style="{ backgroundColor : vr_color1 && vr_color1.color, color: fgCol1, overflowX:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap' }"
                    @click="$emit('setColor', 'volet', 'vr_color1', null, 'coffre')"
                    >
                        {{ vr_color1 ? vr_color1.label : '' }}
                    </div>
                </div>
                <div class="form-group mb-2 row">
                    <label for="" class="small mb-0 col-7">Couleur tablier</label>
                    <div class="form-control form-control-sm col-5"
                    :style="{ backgroundColor : vr_color2 && vr_color2.color, color: fgCol2, overflowX:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap' }"
                    @click="$emit('setColor', 'volet', 'vr_color2', null, 'tablier')"
                    >
                        {{ vr_color2 ? vr_color2.label : '' }}
                    </div>
                </div>
                <div class="form-group mb-2 row">
                    <label for="" class="small mb-0 col-7">Couleur lame finale et coulisses</label>
                    <div class="form-control form-control-sm col-5"
                    :style="{ backgroundColor : vr_color3 && vr_color3.color, color: fgCol3, overflowX:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap' }"
                    @click="$emit('setColor', 'volet', 'vr_color3', null, 'coffre')"
                    >
                        {{ vr_color3 ? vr_color3.label : '' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import {pickColor} from "../../../../../utils/colors";
    export default {
        props : ['currentProduct', 'fitType'],
        data() {
            return {
                vr_type	: null,
                vr_pos	: null,
                vr_grill : false,
                vr_coffre_derriere_linteau : this.fitType && this.fitType.slug === 'neuf',
                vr_color1 : null,
                vr_color2 : null,
                vr_color3 : null,
                typesList : [
                    {id : 1, name : 'Monobloc Moteur filaire Somfy ILMO'},
                    {id : 2, name : 'Monobloc Moteur radio Somfy IO'},
                    {id : 3, name : 'Monobloc Moteur radio MSOFT2 RTS'},
                    {id : 4, name : 'Monobloc Moteur radio SOMFY RTS'},
                    {id : 5, name : 'Monobloc Manuel'},
                ]
            }
        },
        mounted() {
            this.currentProduct.line && this.setProductLine(this.currentProduct.line)
        },
        computed : {
            // blinds () {
            //     return this.$store.state.blinds.items
            // },
            fgCol1() {
                return this.vr_color1 ? pickColor(this.vr_color1.color) : '#333'
            },
            fgCol2() {
                return this.vr_color2 ? pickColor(this.vr_color2.color) : '#333'
            },
            fgCol3() {
                return this.vr_color3 ? pickColor(this.vr_color3.color) : '#333'
            },
        },
        methods : {
            setProductLine (line) {
                if (line) {
                    this.vr_type = line.vr_type
                    this.vr_pos = line.vr_pos
                    this.vr_grill = parseInt(line.vr_grill)
                    this.vr_coffre_derriere_linteau = parseInt(line.vr_coffre_derriere_linteau)
                    this.vr_color1 = this.$store.state.colors.items.find(c => c.id === line.vr_color1)
                    this.vr_color2 = this.$store.state.colors.items.find(c => c.id === line.vr_color2)
                    this.vr_color3 = this.$store.state.colors.items.find(c => c.id === line.vr_color3)
                }
            }
        },
        watch : {
            currentProduct () {
                this.currentProduct.line && this.setProductLine(this.currentProduct.line)
            },
        }
    }
</script>
