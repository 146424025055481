import axios from 'axios'

export default {
    state: {items:[]},
    mutations: {
        setUsers (state, users) {
            state.items = users;
        },
        storeUser (state, user) {
            state.push(user);
        },
        updateUser (state, user) {
            let newstate = state.map(s => {
                if (s.id === user.id) {
                    console.log('updated user', Object.assign({}, s, user));
                    return Object.assign({}, s, user);
                }
                return s;
            });
            state.splice(0, state.length);
            state.push(...newstate);
        }
    },
    actions: {
        getUsers({ commit }) {
            const req = axios.get('/api/users');
            req.then(r => {
                console.log('got users from api', r.data)
                commit('setUsers', r.data);
            }).catch(err => {
                //console.log('could not get users', err)
            })

            return req;
        },

        saveUser({ dispatch }, user) {
            console.log('saving user', user);
            
            let req
            if (user.id) {
                // SET LOGO TO NULL IF NO NEW LOGO - TO PASS REQUEST VALIDATIONS
                user.logo = user.logo && user.logo.name ? user.logo : null

                req = axios.put(`/api/users/${user.id}`, user)
            } else if (user.get('id')) { // IF FORM DATA (NEW LOGO)
                user.append('_method', 'PUT');
                req = axios.post(`/api/users/${parseInt(user.get('id'))}`, user)
            } else {
                req = axios.post(`/api/users`, user)
            }

            // refresh current Logged in user refreshes everything
            req.then(() => {
                dispatch('getUser')
            })

            return req;
        }
    }
};
