import axios from 'axios'

export default {
    state: { items: null },
    mutations: {
        setFills (state, fills) {
            state.items = fills;
        },
    },
    actions: {
        getFills({ commit }) {
            const req = axios.get('/api/fills');

            req.then(r => {
                commit('setFills', r.data);
            }).catch(err => {
                console.log('could not get fills', err)
            });

            return req;
        }
    }
};