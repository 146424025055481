<template>
    <div>
        <div v-if="currentProduct">
            <h5 class="text-uppercase small">Produit</h5>
            <Product ref="product" @setColor="setColor" :currentProduct="currentProduct"></Product>

            <h5 class="text-uppercase small mt-4 pt-4" v-if="currentProduct.has_vr && selectedFitType && (selectedFitType.slug === 'neuf' || selectedFitType.slug === 'renovation')">
                <span class="float-right">
                    <span class="custom-control custom-switch" data-children-count="1">
                        <input type="checkbox" class="custom-control-input" id="activate_vr" v-model="vr">
                        <label class="custom-control-label" for="activate_vr"></label>
                    </span>
                </span>
                Volet
            </h5>
            <hr style="visibility:hidden;height:0;margin:0;padding:0">
            <Volet
                ref="volet"
                @setColor="setColor"
                :currentProduct="currentProduct"
                :fitType="selectedFitType"
                v-if="currentProduct.has_vr && vr && selectedFitType && (selectedFitType.slug === 'neuf' || selectedFitType.slug === 'renovation')"
            ></Volet>
            <h5 class="text-uppercase small mt-4 pt-4">Remplissage</h5>
            <Remplissage ref="remplissage" :currentProduct="currentProduct" :refs="$refs"></Remplissage>
            <h5 class="text-uppercase small mt-4 pt-4">Habillages</h5>
            <Habillage ref="habillage" :currentAccessoire="currentProduct.line && currentProduct.line.product_line_accessories" :product="currentProduct"></Habillage>
            <h5 class="text-uppercase small mt-4 pt-4">Accessoires</h5>
            <Accessoire ref="accessoire" :currentAccessoire="currentProduct.line && currentProduct.line.product_line_accessories"></Accessoire>
            <h5 class="text-uppercase small mt-4 pt-4">Petites fournitures de poses - Non fourni par ALTECH</h5>
            <Fourniture ref="fourniture" :montant="currentProduct.line && currentProduct.line.ht_custom_price"></Fourniture>

            <div class="row">
                <div class="col-6">
                    <button @click="cancel" class="btn btn-secondary btn-flat">Annuler</button>
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-info btn-flat" @click="saveProduct">
                        <i class="fas fa-spin fa-spinner" v-if="saving"></i>
                        <span v-if="currentProduct.line && currentProduct.line.id">Enregistrer le produit</span>
                        <span v-else>Ajouter le produit</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-else class="text-center mt-4 pl-4">
            <button class="btn btn-info" @click="$store.commit('showModal', 'newProduct')"><i class="fas fa-plus"></i> Nouveau produit</button>
        </div>

        <ColorModal
            v-if="modal === 'selectColor'"
            :colorType="colorType"
            :colorLocation="colorLocation"
            @saveColor="saveColor"
        ></ColorModal>

    </div>
</template>
<script>
    // import axios from 'axios'

    import Product from './Product.vue';
    import Accessoire from './Accessoire.vue';
    import Habillage from './Habillage.vue'
    import Remplissage from './Remplissage.vue';
    import Fourniture from './Fourniture.vue';
    import Volet from './Volet.vue';

    import ColorModal from './ColorModal.vue';

    export default {
        name: 'AltechProductDetails',
        data() {
            return {
                colorField : false,
                colorType : null,
                colorLocation : "window",
                vr: this.$store.state.orders.currentProduct && this.$store.state.orders.currentProduct.line && this.$store.state.orders.currentProduct.line.vr,
                saving: false,
            }
        },
        mounted () {
            this.$refs.product && this.$refs.product.$el.scrollIntoView({ behavior: 'smooth' });
        },
        computed: {
            currentProduct () {
                return this.$store.state.orders.currentProduct
            },
            order () {
                return this.$store.state.orders.items && this.$store.state.orders.items.find( o => o.id == this.$route.params.id)
            },
            modal() {
                return this.$store.state.modals.shown
            },
            selectedFitType() {
                if (this.currentProduct.line && typeof this.currentProduct.line.fit_type !== 'undefined') {
                    return this.currentProduct.line.fit_type
                }
                return this.$store.state.fitTypes.items.find(f => this.currentProduct.line.fit_type_id === f.id)
            },
        },
        methods: {
            setColor (ref, color, type, location = "window") {
                console.log("setColor", ref, color, type, location)
                this.colorType = type
                this.colorLocation = location
                this.colorField = {ref : ref, color: color}
                this.$store.commit('showModal', 'selectColor')
            },
            saveColor ({color}) {
                this.$store.commit('setSelectedColor', {color: color, number: this.colorField.color});
                this.$refs[this.colorField.ref][this.colorField.color] = color
            },
            cancel() {
                this.$store.commit('setProduct', null)
            },
            saveProduct () {
                // CHECK VALIDATION IN COMPONENTS, THEN SAVE IF NO ERROR - FUNCTION checkValidation RETURN FALSE/TRUE
                if (this.$store.state.errors.errors.height || this.$store.state.errors.errors.width) {
                    if(!confirm('Vos dimensions sont peut être hors limites d\'utilisation selon les préconisations d\'ADS. Souhaitez-vous enregistrer?')) {
                        return;
                    }
                }
                if (this.$refs.product.checkValidation()) {
                    if (this.$refs.remplissage ? this.$refs.remplissage.checkValidation() : true) {
                        if (this.$refs.accessoire ? this.$refs.accessoire.checkValidation() : true) {
                            this.saving = true;

                            const parts = this.$refs.remplissage && this.$refs.remplissage.parts && this.$refs.remplissage.parts.map((p, i) => ({...p, order : i+1}))


                            this.$store.dispatch('saveLine', {
                                order_id : this.order.id,
                                order_line_id : this.currentProduct ? this.currentProduct.order_line_id : null,
                                line_type : 'product',
                                line_id : this.currentProduct.line ? this.currentProduct.line.id : null,
                                product_id	: this.currentProduct.id,
                                fit_type_id : this.currentProduct.line.fit_type_id,
                                ref : this.$refs.product.ref,
                                width : this.$refs.product.width,
                                height : this.$refs.product.height,
                                quantity : this.$refs.product.quantity,
                                color1_id	: this.$refs.product.color1 && this.$refs.product.color1.id ? this.$refs.product.color1.id : this.$refs.product.color1_id,
                                color2_id : this.$refs.product.color2 && this.$refs.product.color2.id ? this.$refs.product.color2.id : this.$refs.product.color2_id,
                                vr_id : null,
                                vr_type	: this.$refs.volet && this.vr ? this.$refs.volet.vr_type : null,
                                vr_pos	: this.$refs.volet && this.vr ? this.$refs.volet.vr_pos : null,
                                vr_grill : this.$refs.volet && this.vr ? this.$refs.volet.vr_grill : null,
                                vr_coffre_derriere_linteau : this.$refs.volet && this.vr ? this.$refs.volet.vr_coffre_derriere_linteau : null,
                                vr_color1 : this.$refs.volet && this.vr ? this.$refs.volet.vr_color1 && this.$refs.volet.vr_color1.id : null,
                                vr_color2 : this.$refs.volet && this.vr ? this.$refs.volet.vr_color2 && this.$refs.volet.vr_color2.id : null,
                                vr_color3 : this.$refs.volet && this.vr ? this.$refs.volet.vr_color3 && this.$refs.volet.vr_color3.id : null,
                                traverse_id : this.$refs.remplissage ? this.$refs.remplissage.traverse_id : null,
                                traverse_quantity : this.$refs.remplissage ? this.$refs.remplissage.traverse_quantity : null,
                                ht_custom_price : this.$refs.fourniture ? this.$refs.fourniture.ht_custom_price : null,
                                product_line_parts : parts,
                                product_line_accessories : this.$refs.habillage.accessories.concat(this.$refs.accessoire.accessoriesToSave.filter(a => a.accessory_id))

                            }).then((res) => {
                                this.saving = false;

                                const msg = res.data.line.product_line_parts.map((pl, i) => {
                                    if (pl.fill_changed) {
                                        return 'Le remplissage du segment ' + (i + 1) + ' a été remplacé par ' + pl.part_fill.name + ' car la surface du segment était trop importante';
                                    }
                                })
                                if (msg.join('')) {
                                    alert(msg.join("\n"));
                                }
                            }).catch((e) => {
                                this.saving = false;
                                console.log('error', e.response)
                                if (e.response.status === 500) {
                                    alert ('Erreur serveur : ' + e.response.data.message)
                                }
                                this.$refs.product.$el.scrollIntoView({ behavior: 'smooth' });
                            })
                        } else {
                            this.$refs.accessoire.$el.scrollIntoView({ behavior: 'smooth' });
                        }
                    } else {
                        if (this.$refs.accessoire && (!this.$refs.accessoire.checkValidation())) {
                            this.$refs.accessoire.$el.scrollIntoView({ behavior: 'smooth' });
                        }
                        this.$refs.remplissage.$el.scrollIntoView({ behavior: 'smooth' });


                    }
                } else {
                    this.$refs.accessoire && this.$refs.accessoire.checkValidation();
                    this.$refs.product.$el.scrollIntoView({ behavior: 'smooth' });
                }
            }
        },
        components: {
            Product,
            Accessoire,
            Habillage,
            Remplissage,
            Fourniture,
            Volet,
            ColorModal,

        }
    }
</script>

<style>
  .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .style-chooser ul[role="listbox"]{
      font-size:10px;
      width: 400px;
  }

</style>
