import axios from 'axios'

// Format is category -> subcategories -> products
export default {
    state: { items: null },
    mutations: {
        setProducts (state, items) {
            state.items = items;
        },
    },
    actions: {
        getProducts({ commit }) {
            const req = axios.get('/api/products');

            req.then(r => {
                commit('setProducts', r.data);
            }).catch(err => {
                console.log('could not get products', err)
            })

            return req;
        }
    }
};