import axios from 'axios'

export default {
    state: { current: null },
    mutations: {
        setMessage (state, message) {
            state.current = message;
        },
    },
    actions: {
        getMessage({ commit }) {
            const req = axios.get('/api/messages');
            req.then(r => {
                commit('setMessage', r.data);
            })
            return req;
        },
        storeMessage ({ commit }, message) {
            console.log('setMessage', message)
            const req = axios.post('/api/messages', message);
            req.then(r => {
                commit('setMessage', r.data);
            })
            return req;
        },
    }
};