import axios from 'axios'
import {router} from "../app.js"

export default {
    state: { items: [], currentProduct : null },
    mutations: {
        setOrders (state, items) {
            state.items = Array.isArray(items) ? items : [];
        },
        setProduct (state, product) {
            state.currentProduct = product

        },
        storeOrder (state, order) {
            state.items.push(order);
        },
        storeLine (state, orderline) {
            state.items = state.items.map(o => {
                if (o.id === orderline.order_id) {
                    const orderlinesFiltered = o.order_lines.filter(l => l.id !== orderline.id)

                    orderlinesFiltered.push(orderline)
                    return {
                        ...o,
                        order_lines : orderlinesFiltered
                    }
                }

                return o;
            })
        },
        updateOrder (state, order) {
            state.items = state.items.map(s => s.id === order.id ? order : s)
        },
        deleteOrder (state, order) {
            state.items = state.items.filter(o => o.id !== order.id)
        },
        deleteLine (state, orderline) {
            state.items = state.items.map(o => o.id === orderline.order_id ? {...o, order_lines : o.order_lines.filter(l => l.id !== orderline.id)} : o)
        },
    },
    actions: {
        getOrders({ commit }) {
            const req = axios.get('/api/orders');
            req.then(r => {
                commit('setOrders', r.data);
            }).catch(err => {
                console.log('could not get orders', err)
            });

            return req;
        },
        storeOrder({commit}, order) {
            const req = axios.post(`/api/orders`, order)
            req.then((resp) => {
                console.log('storeOrder', resp.data)
                order.id ? commit('updateOrder', resp.data) : commit('storeOrder', resp.data);
                if (resp.data.is_estimate && !order.id) router.push(`/devis/${resp.data.id}`)
            }).catch((err) => {
                console.log('err', err)
            });
            return req;
        },
        saveLine({commit}, line) {
            console.log('saveline', line)
            const req = axios.post(`/api/orders/${line.order_id}/orderlines`, line)
            req.then( res => {
                commit('storeLine', res.data);
                router.push(`/devis/${line.order_id}`)
                commit('setProduct',null)
            }).catch((err) => {
                console.log('err', err, err.response.data.errors)
                commit('setErrors', err.response.data.errors);
            });
            return req;
        },
        deleteOrder({commit}, order) {
            const req = axios.delete(`/api/orders/${order.id}`, order)
            req.then(res => {
                // NO RES FROM SERV
                commit('deleteOrder', order);
            })
                .catch((err) => {
                    commit('setErrors', err.response.data.errors);
                });
            return req;
        },
        deleteLine({commit}, orderline) {
            if (confirm(`La suppression du produit ${orderline.line.ref.toUpperCase()} est définitive. Souhaitez-vous continuer ?`)) {
                const req = axios.delete(`/api/orders/${orderline.order_id}/orderlines/${orderline.id}`, orderline)
                req.then( res => {
                    // NO RES FROM SERV
                    commit('deleteLine', orderline);
                    router.push(`/devis/${orderline.order_id}`)
                }).catch((err) => {
                    console.log('err', err)
                });
                return req;
            }
        },
    }
};
