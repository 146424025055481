import axios from 'axios'


export default {
    state: { data: null },
    mutations: {
        setUser (state, user) {
            state.data = user;
        },
    },
    actions: {
        getUser({ commit, dispatch }) {
            const req = axios.get('/api/users/me');

            req.then(r => {
                console.log('got user from api', r.data)
                commit('setUser', r.data);
                commit('setColors');
                dispatch('getAccessories');
                dispatch('getFills');
                dispatch('getProducts');
                dispatch('getEstimateStatuses');
                dispatch('getStatuses');
                dispatch('getTraverses');
                dispatch('getRoles');
                dispatch('getOrders');
                dispatch('getBlinds');
                dispatch('getFitTypes');
                dispatch('getMessage');
                dispatch('getUsers').catch(() => {});
                dispatch('getColors');
                dispatch('getWood');
            }).catch(err => {
                console.log('could not get user', err)
            })

            return req;
        },
        logout({commit}) {
            axios.get('/logout').then(r => {
                commit('setUser', null)
            })
        }
    }
};