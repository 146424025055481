import axios from 'axios'

export default {
    state: { colors: null },
    mutations: {
        setSelectedColor (state, { color, number }) {
            state.colors = { ... state.colors, [number]: {...color} };
        },
    },
};
