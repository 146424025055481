<template>
    <div>
        <div class="row pt-5 pb-5">
            <div class="fields col-md-12">
                <div class="row">
                    <div class="col-4"><a href="#" class="btn btn-tool" @click.prevent="$router.push('/')"><i class="fas fa-chevron-left"></i> Retour</a></div>

                    <div class="col-lg-12">
                        <div class="card m-0" style="background: transparent;box-shadow: none;">
                            <div class="py-0">
                                <div class="row mt-3">
                                    <div class="col-2 pt-4">
                                        <h2 class="card-title">
                                            <span class="ml-2">
                                                Mes devis ({{ estimates.length }})
                                            </span>
                                        </h2>
                                    </div>
                                    <div class="col-6 pt-4">
                                        <button type="button" class="btn btn-outline-infox btn-sm text-info" @click="$store.commit('showModal', 'newEstimate')"><i class="fas fa-plus"></i>  <span class="ml-1"> Créer un devis</span> </button>
                                    </div>
                                    <div class="col-2">
                                        <div class="form-group">
                                            <label for="" class="small mb-0">Statut</label>
                                            <select class="form-control form-control-sm" v-model="filterByStatus"> <!--  @change="sortIncidents()" -->
                                                <option value=''>Tout afficher</option>
                                                <option v-for="status in statuses" :value="status.id" :key="status.id">
                                                    {{ status.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="form-group">
                                            <label for="" class="small mb-0">Trier</label>
                                            <select class="form-control form-control-sm" v-model="orderBy"> <!--  v-on:change="sortIncidents()" -->
                                                <option v-for="(item, index) in orderChoice" :value="item.property" :key="index">
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-white">
                                <table class="table table-borderedx table-striped bg-white" style="font-size: 15px;">
                                    <thead class="text-uppercase">
                                        <th class="small border-top-0 pr-1">N°</th>
                                        <th class="small border-top-0 pr-1">Date de création</th>
                                        <th class="small border-top-0 pr-1">Date de modification</th>
                                        <th class="small border-top-0 pr-1">Nom du chantier</th>
                                        <th class="small border-top-0 pr-1">Contact</th>
                                        <th class="small border-top-0 pr-1">Ville</th>
                                        <th class="small border-top-0 pr-1">Montant HT</th>
                                        <th class="small border-top-0 pr-1">Statut</th>
                                        <th class="small border-top-0 pr-1"></th>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in estimates" :key="item.id">
                                        <td class="pr-1"> {{ item.id }} </td>
                                        <td class="pr-1"> {{ frenchDate(item.created_at) }} </td>
                                        <td class="pr-1"> {{ frenchDate(item.updated_at) }} </td>
                                        <td class="pr-1 font-weight-bold"> {{ item.title }} </td>
                                        <td class="pr-1"> {{ item.contact }} </td>
                                        <td class="pr-1"> {{ item.cpcity }} </td>
                                        <!-- CALC ESTIMATE PRICE + HT CUSTOM PRICE -->
                                        <td class="pr-1">
                                            {{ item.order_lines ? (Math.round(item.order_lines.map(l => l.estimate_price  / 100).concat(item.order_lines.map(l => l.line && l.line.ht_custom_price ? l.line.ht_custom_price : 0)).reduce((a, b) => a + b, 0) * 100) / 100).toLocaleString('fr', { minimumFractionDigits: 2 }) : '0.00' }} €</td>

                                        <td class="pr-1 py-1">
                                            <select class="form-control form-control-sm" v-model="item.estimate_status_id" @change="$store.dispatch('storeOrder', item)">
                                                <option v-for="status in statuses" :value="status.id" :key="status.id">
                                                    {{ status.name }}
                                                </option>
                                            </select>
                                        </td>
                                        <td class="pr-1">
                                            <button v-if="item.estimate_status_id == 1" class="btn p-0 ml-2" @click="$router.push(`/devis/${item.id}`)"  title="Modifier">
                                                <i class="fas fa-pen small"></i>
                                            </button>
                                            <button class="btn p-0 ml-2"  title="Télécharger">
                                                <a :href="'/pdf/quote/' + item.id" target="_blank" style='color: black;'><i class="fas fa-download small"></i></a>
                                            </button>
                                            <button class="btn p-0 ml-2" @click="deleteOrder(item)"  title="Supprimer">
                                                <i class="fas fa-trash small"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="estimates && !estimates.length">
                                        <td style="text-align: center" colspan="9">Aucun devis ne correspond</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {frenchDate} from '../../utils/dates'
    export default {
        name: 'EstimatesList',
        data() {
            return {
                orderBy : 'created_at',
                orderChoice: [
                                {name : 'Date de création',  property : 'created_at'},
                                {name : 'Date de modification',  property : 'updated_at'},
                                {name : 'Chantier',  property : 'title'},
                            ],
                filterByStatus : '',
                frenchDate,
            }
        },
        computed: {
            estimates() {
                // return this.$store.state.user.data ? this.$store.state.user.data.orders
                return this.$store.state.orders.items ? this.$store.state.orders.items
                    .filter(o => o.is_estimate)
                    .filter(o => this.filterByStatus ? o.estimate_status_id == this.filterByStatus : true)
                    .sort((a, b) => typeof a[this.orderBy] === "string" ? a[this.orderBy].localeCompare(b[this.orderBy]) : a[this.orderBy] > b[this.orderBy] ? 1 : -1)
                : [];
            },
            statuses () {
                return this.$store.state.estimateStatuses.items;
            },
        },
        methods : {
            deleteOrder(item) {
                if (confirm(`Souhaitez-vous supprimer définitivement le devis n°${item.id} ?`)) {
                    this.$store.dispatch('deleteOrder', item)
                }
            }
        },
    }
</script>
