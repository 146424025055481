<template>
    <div>
        <div class="card text-white bg-secondary mb-3" v-if="message">
            <div class="card-body py-2">
                <p v-if="message" class="card-text">{{ message.name }}</p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'HomeAlert',
        data() {
            return {
            }
        },
        computed : {
            message () {
                return this.$store.state.messages.current
            }
        }
    }
</script>