<template>
<div class="card">
    <div class="card-body">
        <div class="row" v-if="traverses && traverses.length">
            <!-- TRAVERSES -->
            <div class="col-lg-6">
                <div class="form-group">
                    <label for="" class="small mb-0">Traverses</label>
                    <input class="form-control form-control-sm" type="number" max="5" min="0" v-model="traverse_quantity" />
                </div>
            </div>
            <!-- TYPE TRAVERSES -->
            <div class="col-lg-6">
                <div class="form-group" v-if="traverse_quantity && traverse_quantity > 0">
                    <label for="" class="small mb-0">Type de traverse</label>
                    <select class="form-control form-control-sm" v-model="traverse_id">
                        <option  v-for="item in traverses" :key="item.id" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div v-for="(segment, index) in parts" :key="index">
                    <div class="col-lg-12" v-if="currentProduct.has_tr && traverse_quantity > 0">
                        Segment {{ index + 1 }}
                        <!-- <i class="fas fa-trash ml-3 small" @click="deleteSegment(segment)"></i> -->
                        <small v-if="index === 0">(bas)</small>
                        <small v-if="index === (parts.length - 1)">(haut)</small>
                    </div>
                    <div class="col-lg-12">
                        <Segment
                            :nparts="parts.length"
                            :segment="segment"
                            :submitted="submitted"
                            ref="segment"
                            :product="currentProduct"
                            :index="index"
                            :lsh="heightLeft"
                            :traverses="traverse_quantity"
                        ></Segment>
                    </div>
                    <div class="col-lg-12" v-if="errors && errors.product_line_parts && errors.product_line_parts.length">
                        <div class="text-danger small" v-for="pe in errors.product_line_parts">
                            {{ pe }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import { required } from 'vuelidate/lib/validators'
    import Segment from './Segment.vue'

    export default {
        props : ['currentProduct', 'refs'],
        data() {
            return {
                submitted : false,
                parts : [{fill_type_id : null, fill_id : null, wood_vert : 0, wood_hor: 0, height : null}],
                traverse_id : null,
                traverse_quantity : 5,
            }
        },
        mounted () {
            this.traverse_quantity = 1;
            if (this.currentProduct.line) {
                console.log('has line', this.currentProduct.line)
                this.setProductLine(this.currentProduct.line)
            }
        },
        computed : {
            traverses () {
                return this.$store.state.traverses.items.filter(t => t.code === this.currentProduct.trav_1 || t.code === this.currentProduct.trav_2)
            },
            errors() {
                return this.$store.state.errors.errors;
            },
            productHeight () {
                if (this.currentProduct.line) {
                    return parseInt(this.currentProduct.line.height, 10)
                }
                return this.refs && this.refs.product.height
            },
            heightLeft() {
                return this.productHeight - this.partsHeight
            },
            partsHeight() {
                let segHeight = 0
                if (this.parts.length > 1) {
                    this.parts.forEach((p, i) => {
                        if (i < this.parts.length - 1) {
                            segHeight += parseInt(p.height, 10)
                        }
                    });
                }

                return segHeight
            }
        },
        methods  : {
            addSegment () {
                this.parts.push({fill_type_id : null, fill_id : null, wood_vert : 0, wood_hor: 0, height : 0})
            },
            deleteSegment (i) {
                this.parts = this.parts.filter((p, index) => index !== i);
            },
            setProductLine (line) {
                if (line) {
                    this.traverse_id = line.traverse_id ? line.traverse_id : null;
                    this.traverse_quantity = line.traverse_quantity ? line.traverse_quantity : 0;

                    this.parts = line.product_line_parts ? line.product_line_parts.map(p => ({...p, fill_type_id : p.part_fill.fill_type_id})) : [{fill_type_id : null, fill_id : null, wood_vert : 0, wood_hor: 0, height : 0, shape: false}]
                }
            },
            checkValidation () {
                let ret = true;
                this.$refs.segment.forEach((s, i) => {
                    if (!s.checkValidation()) {
                        console.log('segment '+i+' is invalid', s)
                        document.getElementById('segment-'+i).scrollIntoView()
                        ret = false;
                    }
                })

                this.parts = this.$refs.segment.map(s => {
                    return {
                        fill_type_id : s.fill_type_id,
                        fill_id : s.fill_id,
                        fill_type : s.fill_type,
                        fill : s.fill,
                        wood_vert : s.wood_vert,
                        wood_hor: s.wood_hor,
                        height : s.height,
                        wood_id: s.wood_id,
                        wood: s.wood,
                        shape: s.shape,
                    }
                })

                console.log('remplissage validation returning', ret)

                return ret;
            }
        },
        watch : {
            currentProduct () {
                this.currentProduct.line && this.setProductLine(this.currentProduct.line)
            },
            productHeight () {
                if (this.parts.length === 1) {
                    this.$refs.segment[0].setHeight(parseInt(this.productHeight, 10))
                }
            },
            traverse_quantity (current, previous) {
                let v = parseInt(current, 10)

                if (!v) {
                    v = 0;
                }

                if (parseInt(previous, 10) === 0 && v === 1 && this.traverse_id === null && this.traverses && this.traverses.length) {
                    this.traverse_id = this.traverses[0].id;
                }
                // V+1 : NEED QUANTITY + 1 SEGMENTS

                // IF LESS PARTS THAN CURRENT QUANTITY
                if (this.parts && this.parts.length > v + 1) { // DID THIS WAY SO WE DON'T DELETE CURRENT VALUES
                    const toDelete = this.parts.length + 1
                    for (let i = v+1; i < toDelete; i++) {
                        this.deleteSegment(v+1)
                    }
                // IF MORE PARTS THAN CURRENT QUANTITY
                } else {
                    const toAdd = v+1 - this.parts.length
                    for (let i = 0; i < toAdd; i++) {
                        this.addSegment()
                    }
                }

            }
        },
        components: {
            Segment,
        }
    }
</script>
