<template>
    <div>
        <form @submit.prevent="save">
            <div class="row p-4">
                <div class="mb-3 col-lg-6 border-right">
                    <h6 class="mb-4 font-weight-bold"> Informations </h6>
                    <ul class="list-unstyled pb-0 mb-0">
                        <li class="mb-2 row">
                            <div class="col-md-4">Titre : </div>
                            <div class="col-md-6">
                                <input id="firstname" v-model="order.title" type="text" class="form-control form-control-sm" disabled/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-4">Statut : </div>
                            <div class="col-md-6">
                                <select class="form-control form-control-sm" v-model="order.status_id">
                                    <option v-for="status in statuses" :value="status.id" :key="status.id">
                                        {{ status.name }}
                                    </option>
                                </select>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-4">Adresse : </div>
                            <div class="col-md-6">
                                <input  v-model="order.address" type="text" class="form-control form-control-sm" disabled/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-4">Code Postal / Ville : </div>
                            <div class="col-md-6">
                                <input  v-model="order.cpcity" type="text" class="form-control form-control-sm" disabled/>
                            </div>
                        </li>
                        <li class="mb-2 row">
                            <div class="col-md-4">Prix : </div>
                            <div class="col-md-6">
                                <input  v-model="order.price" type="text" class="form-control form-control-sm" disabled/>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mb-3 col-lg-6 pl-4">
                    <h6 class="mb-4 font-weight-bold"> Livraison </h6>
                    <ul class="list-unstyled pb-0 mb-0">
                        <li class="mb-3 row">
                            <div class="col-md-6">Date de livraison : </div>
                            <div class="col-md-6">
                                <input class="form-control form-control-sm" id="date" type="date" v-model="order.delivery_date">
                            </div>
                        </li>
                        <!-- statut, la date de livraison et d’uploader l’ARC, le bon de livraison et la facture. -->
                        <li class="mb-2">
                            <label for="arc" class=" control-label small"><b>Télécharger l'ARC </b></label>
                            <EditDocument :type="'arc'" :document_file="arc_document" :currentOrder="currentOrder" @setDocumentValue="setDocumentValue"></EditDocument>
                        </li>
                        <li class="mb-2">
                            <label for="delivery" class=" control-label small"><b>Télécharger le bon de livraison </b></label>
                            <EditDocument :type="'delivery'" :document_file="delivery_document" :currentOrder="currentOrder" @setDocumentValue="setDocumentValue"></EditDocument>
                        </li>
                        <li class="mb-2">
                            <label for="fac" class=" control-label small"><b>Télécharger la facture </b></label>
                            <EditDocument :type="'bill'" :document_file="bill_document" :currentOrder="currentOrder" @setDocumentValue="setDocumentValue"></EditDocument>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
    import axios from 'axios'
    import EditDocument from './EditDocument.vue'
    import {sqlDate} from "../../utils/dates";

    export default {
        name: 'OrderForm',
        props: ['currentOrder'],
        data() {
            return {
                saving: false,
                submitted: false,

                order : {
                    title : null,
                    contact : null,
                    address : null,
                    cpcity : null,
                    vat : null,
                    manager : null,
                    price : null,
                    first_visit_date : null,
                    user_id : null,
                    status_id : null,
                    delivery_date : null,
                },
                delivery_document : null,
                arc_document : null,
                bill_document : null,

            }
        },
        watch: {
            currentOrder() {
                this.setCurrentOrder()
            }
        },
        computed: {
            statuses () {
                return this.$store.state.statuses.items;
            },
            user() {
                return this.$store.state.user.data;
            },
        },
        mounted () {
            this.setCurrentOrder()
        },
        methods: {
            setCurrentOrder () {
                this.order = this.currentOrder

                if (typeof this.order.delivery_date === "string") {
                    this.order.delivery_date = sqlDate(this.order.delivery_date);
                }

                this.delivery_document = this.currentOrder.documents.find(d => d.type === 'delivery')
                this.arc_document = this.currentOrder.documents.find(d => d.type === 'arc')
                this.bill_document = this.currentOrder.documents.find(d => d.type === 'bill')
            },
            setDocumentValue (v) {
                this[v.type+'_document'] =  v.value
            },
            async save () {
                this.saving = true;
                this.submitted = true;

                if (this.delivery_document && this.delivery_document.name) {
                    let data = new FormData();
                    data.append('document', this.delivery_document);
                    data.append('type', 'delivery');
                    data.append('documentable_type', 'order');

                    try {
                        const res = await axios.post(`/api/orders/${this.currentOrder.id}/documents`, data)
                        this.order.documents.push(res.data);
                    } catch (e) {
                        alert(e.response.data.message)
                        this.saving = false;
                        return false;
                    }
                }
                if (this.arc_document && this.arc_document.name) {
                    let data = new FormData();
                    data.append('document', this.arc_document);
                    data.append('type', 'arc');
                    data.append('documentable_type', 'order');

                    try {
                        const res = await axios.post(`/api/orders/${this.currentOrder.id}/documents`, data)
                        this.order.documents.push(res.data);
                    } catch (e) {
                        alert(e.response.data.message)
                        this.saving = false;
                        return false;
                    }
                    this.order.status_id = 4 // En production = Le statut change au dépôt de l’ARC
                }
                if (this.bill_document && this.bill_document.name) {
                    let data = new FormData();
                    data.append('document', this.bill_document);
                    data.append('type', 'bill');
                    data.append('documentable_type', 'order');
                    try {
                        const res = await axios.post(`/api/orders/${this.currentOrder.id}/documents`, data)
                        this.order.documents.push(res.data);
                    } catch (e) {
                        alert(e.response.data.message)
                        this.saving = false;
                        return false;
                    }
                }
                if (this.bill_document && this.delivery_document) { // Livré : Passage automatique déclenché par le dépôt du bon de livraison et de la facture sur l’extranet
                    this.order.status_id = 6
                }
                this.saving = false;
                return this.order;
            }
        },
        components : {
            EditDocument,
        },
    }
</script>
