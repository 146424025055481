<template>
<div class="card">
    <div class="card-body">
        <div class="row">
            <!-- INFOS PRODUITS -->

            <div class="col-lg-8" style="display: flex;align-items: center;">
                <h5 class="text-uppercase small">
                    {{ currentProduct && currentProduct.label}} <br />
                    <span>{{ fitType && fitType.name }}</span>
                </h5>
            </div>
            <div class="col-lg-4">
                <img :alt="currentProduct.img_code" v-if="currentProduct && currentProduct.img_code" class="card-img-top" :src="`/images/products/${currentProduct.img_code}.jpg`"  style="max-height: 100px;object-fit: contain;"/>
            </div>
        </div>
        <!-- REPERES@ -->
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label for="" class="small mb-0" :class="{ 'text-danger': submitted && $v.ref.$error }">Repère*</label>
                    <input class="form-control form-control-sm" v-model="ref" :class="{ 'is-invalid': submitted && $v.ref.$error }" />
                    <div class="text-danger small" v-if="submitted && $v.ref.$error"><b>Référence obligatoire</b></div>
                </div>
            </div>
        </div>
        <!-- DIMENSIONS -->
        <div class="row">
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="" class="small mb-0" :class="{ 'text-danger': (submitted && $v.height.$error) || (errors && errors.height && errors.height.length) }">Hauteur (mm)*</label>
                    <input min="0" type="number" class="form-control form-control-sm" v-model="height" :class="{ 'is-invalid': (submitted && $v.height.$error) || (errors && errors.height && errors.height.length) }"/>
                    <div class="text-danger small" v-if="submitted && $v.height.$error"><b>Hauteur obligatoire</b></div>
                    <div class="text-warning small" v-if="errors && errors.height && errors.height.length">
                        <b>{{ errors.height[0] }}</b> <br />
                        <!--Dimension hors-limite, veuillez vous rapprocher de notre service commercial.-->
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="" class="small mb-0" :class="{ 'text-danger': (submitted && $v.width.$error) || (errors && errors.width &&  errors.width.length) }">Largeur (mm)*</label>
                    <input min="0" type="number" class="form-control form-control-sm" v-model="width" :class="{ 'is-invalid': (submitted && $v.width.$error) || (errors && errors.width &&  errors.width.length) }"/>
                    <div class="text-danger small" v-if="submitted && $v.width.$error"><b>Largeur obligatoire</b></div>
                    <div class="text-warning small" v-if="errors && errors.width &&  errors.width.length">
                        <b>{{ errors.width[0] }}</b> <br />
                        <!--Dimension hors-limite, veuillez vous rapprocher de notre service commercial.-->
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="quantity" class="small mb-0" :class="{ 'text-danger': submitted && $v.quantity.$error }">Quantité*</label>
                    <input min="0" id="quantity" type="number" class="form-control form-control-sm" v-model="quantity" :class="{ 'is-invalid': submitted && $v.quantity.$error }"/>
                    <div class="text-danger small" v-if="submitted && $v.quantity.$error"><b>Quantité obligatoire</b></div>
                </div>
            </div>
        </div>
        <!-- COULEURS -->
        <div class="row">
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="" class="small mb-0">Type de couleur</label>
                    <select class="form-control form-control-sm" v-model="color1.type" @change="colorTypeChanged">
                        <option value="satin">Satiné</option>
                        <option value="structure">Structuré mat</option>
                    </select>
                </div>
            </div>
            <div :class="isVerriere ? 'col-lg-8' : 'col-lg-6'">
                <div class="form-group">
                    <label for="" class="small mb-0" :class="{ 'text-danger': submitted && $v.color1.$error }">Couleur <span v-if="severalTone">intérieure</span>*</label>

                    <div class="form-control form-control-sm"
                         :style="{ backgroundColor : color1 && color1.color, cursor:'pointer', color: fgCol1}"
                         v-if="enableCode && color1 && color1.type"
                         @input="setColor('code', 'color1', $event)"
                         :class="{ 'is-invalid': submitted && $v.color1.$error }"
                         @click="$emit('setColor', 'product', 'color1', color1.type)">
                        {{color1 && color1.label ? color1.label : ''}}
                    </div>
                    <div v-else class="form-control form-control-sm" style="background-color: #e9ecef" :class="{ 'is-invalid': submitted && $v.color1.$error }"></div>

                    <div class="text-danger small" v-if="submitted && $v.color1.$error"><b>Couleur obligatoire</b></div>
                </div>
            </div>
            <div class="col-lg-2" v-if="!isVerriere">
                <label>&nbsp;</label>
                <div class="form-check" style="">
                    <label class="form-check-label" for="bicolore">
                        <input class="form-check-input" type="checkbox" v-model="severalTone" id="bicolore">
                        Bicolore</label>
                </div>
            </div>
        </div>

        <div class="row" v-if="severalTone && !isVerriere">
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="" class="small mb-0">Type de couleur</label>
                    <select class="form-control form-control-sm" v-model="color2.type" @change="color2 = {type: color2.type}">
                        <option value="satin">Satiné</option>
                        <option value="structure">Structuré mat</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="form-group">
                    <label for="" class="small mb-0" :class="{ 'text-danger': submitted && $v.color2.$error }">Couleur extérieure*</label>

                    <div class="form-control form-control-sm"
                         :style="{ backgroundColor : color2 && color2.color, cursor:'pointer', color: fgCol2}"
                         v-if="enableCode && color2 && color2.type"
                         @input="setColor('code', 'color2', $event)"
                         :class="{ 'is-invalid': submitted && $v.color2.$error }"
                         @click="$emit('setColor', 'product', 'color2', color2.type)">
                        {{color2 && color2.label ? color2.label : ''}}
                    </div>
                    <div v-else class="form-control form-control-sm" style="background-color: #e9ecef" :class="{ 'is-invalid': submitted && $v.color2.$error }"></div>

                    <div class="text-danger small" v-if="submitted && $v.color2.$error"><b>Couleur obligatoire</b></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import { required, minValue } from 'vuelidate/lib/validators'
    import {pickColor} from "../../../../../utils/colors";

    export default {
        props : ['currentProduct'],
        data() {
            return {
                submitted : false,

                product_id	: null,
                ref : null,
                width : null,
                height : null,
                quantity : null,
                color1	:  this.$store.state.selectedColors.colors ? {...this.$store.state.selectedColors.colors.color1} : {},
                color2 : this.$store.state.selectedColors.colors ? {...this.$store.state.selectedColors.colors.color2} : {},

                severalTone : false,
                enableRAL : true,
                enableCode : true,

            }
        },
        validations: {
            ref : {required},
            width : {
                required,
                minValue: 0,
                minimumv: (v) => parseInt(v, 10) > 0,
            },
            height : {
                required,
                minValue: 0,
                minimumv: (v) => parseInt(v, 10) > 0,
            },
            quantity : {
                required,
                minValue: 0,
                minimumv: (v) => parseInt(v, 10) > 0,
            },
            color1 : {required},
        },
        computed : {
            isVerriere() {
                return this.currentProduct && this.currentProduct.category && this.currentProduct.category.parent && this.currentProduct.category.parent.slug === 'verriere'
            },
            errors () {
                return this.$store.state.errors.errors
            },
            fitType () {
                return this.$store.state.fitTypes.items.find(t => t.id === this.currentProduct.line.fit_type_id)
            },
            fgCol1() {
                return this.color1 && this.color1.color ? pickColor(this.color1.color) : '#333'
            },
            fgCol2() {
                return this.color2 && this.color2.color ? pickColor(this.color2.color) : '#333'
            },
        },
        methods : {
            colorTypeChanged(e) {
                this.color1 = {type: e.target.value}
            },
            setColor (type, number, e) {
                console.log('test', type,number)
                this[number] = e.target.value
                if (type === 'ral') {
                    this.enableCode = false;
                    this.enableRAL = true
                }
                if (type === 'code') {
                    this.enableRAL = false;
                    this.enableCode = true
                }
                if (!e.target.value && !this.color1 && !this.color2) {
                    this.enableRAL = true
                    this.enableCode = true
                }
            },
            setProductLine (line) {
                if (line) {
                    console.log('currentProduct', this.currentProduct)
                    this.ref = line.ref
                    this.width = line.width
                    this.height = line.height && !isNaN(parseInt(line.height)) ? parseInt(line.height) : null;
                    this.quantity = line.quantity
                    if (line.color1_id) {
                        console.log('color1', line.color1_id)
                        this.color1 = {...this.$store.state.colors.items.find(c => c.id === line.color1_id)}
                    }
                    if (line.color2_id) {
                        console.log('color2', line.color2_id)
                        console.log('this.$store.state.colors.items.find(c => c.name === line.color2)', this.$store.state.colors.items.find(c => c.id === line.color2_id))
                        this.color2 = {...this.$store.state.colors.items.find(c => c.id === line.color2_id)}
                    }

                    this.severalTone = !!line.color2_id;
                }
            },
            checkValidation () {

                console.log('checking product validation')
                this.$v.$touch()
                if (this.$v.$invalid/* || this.quantity <= 0 || this.height <= 0 || this.width <= 0*/) {
                    this.submitted = true
                    return false
                } else {
                    return true
                }
            }
        },
        mounted () {
            this.currentProduct.line && this.setProductLine(this.currentProduct.line)

            if (!this.color1 || !this.color1.color) {
                this.color1 = this.$store.state.selectedColors.colors ? {...this.$store.state.selectedColors.colors.color1} : {};
            }
            if (!this.color2 || !this.color2.color) {
                this.color2 = this.$store.state.selectedColors.colors ? {...this.$store.state.selectedColors.colors.color2} : {}
            }
        },
        watch : {
            color1 () {
                if ((this.color1 || this.color2) && this.enableRAL && this.enableCode) {
                    this.enableRAL = false;
                    this.enableCode = true
                }
                if (!this.color1 && !this.color2) {
                    this.enableRAL = true;
                    this.enableCode = true
                }
            },
            color2 () {
                if ((this.color1 || this.color2) && this.enableRAL && this.enableCode) {
                    this.enableRAL = false;
                    this.enableCode = true
                }
                if (!this.color1 && !this.color2) {
                    this.enableRAL = true;
                    this.enableCode = true
                }
            },
            severalTone(v) {
                if (v === false) {
                    this.color2 = {};
                }
            },
            currentProduct (v) {
                this.currentProduct.line && this.setProductLine(this.currentProduct.line)
                this.product_id = this.currentProduct.id
            },
             height(v) {
                 const p = {...this.currentProduct}
                 this.$store.commit('setErrors', {...this.$store.state.errors.errors, height: null});
                 if (p.max_height && v > p.max_height) {
                     this.$store.commit('setErrors', {...this.$store.state.errors.errors, height: ['La hauteur est supérieure au maximum préconisé pour ce produit ('+p.max_height+'mm)']})
                 }
                 if (p.min_height && v < p.min_height) {
                     this.$store.commit('setErrors', {...this.$store.state.errors.errors, height: ['La hauteur est inférieure au minimum pour ce produit ('+p.min_height+'mm)']})
                 }
                 this.currentProduct.line = {...this.currentProduct.line, height: v}
             },
             width(v) {
                 const p = {...this.currentProduct}
                 this.$store.commit('setErrors', {...this.$store.state.errors.errors, width: null});

                 if (p.max_width && v > p.max_width) {
                     this.$store.commit('setErrors', {...this.$store.state.errors.errors, width: ['La largeur est supérieure au maximum préconisé pour ce produit ('+p.max_width+'mm)']})
                 }
                 if (p.min_width && v < p.min_width) {
                     this.$store.commit('setErrors', {...this.$store.state.errors.errors, width: ['La largeur est inférieure au minimum pour ce produit ('+p.min_width+'mm)']})
                 }
                 this.currentProduct.line = {...this.currentProduct.line, width: v}
             }
        }
    }
</script>
