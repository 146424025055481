import user from './user.js'
import accessories from './accessories.js'
import fills from './fills.js'
import products from './products.js'
import statuses from './statuses.js'
import estimateStatuses from './estimateStatuses.js'
import traverses from './traverses.js'
import roles from './roles.js'
import modals from './modals.js';
import orders from './orders.js';
import users from './users.js';
import colors from './colors.js';
import blinds from './blinds.js';
import fitTypes from './fitTypes.js';
import messages from './messages.js';
import errors from './errors.js';
import selectedColors from './selectedColors.js';
import wood from './wood.js';


export default {
    modules: {
        user,
        accessories,
        fills,
        products,
        statuses,
        estimateStatuses,
        traverses,
        roles,
        modals,
        orders,
        users,
        colors,
        blinds,
        fitTypes,
        messages,
        errors,
        selectedColors,
        wood,
    }
}