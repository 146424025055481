<template>
    <div class="modal-backdrop">
        <div class="modal" tabindex="-1" role="dialog" style="display: block" >
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b>Choix couleur</b>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="$store.commit('hideModal')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form @submit.prevent="save">
                        <div class="row p-4">
                            <div class="mb-3 col-lg-12">
                                <ul class="list-unstyled pb-0 mb-0">
                                    <li class="mb-2 row" v-for="(color, i) in activeColors" :key="i" @click="activeColor = color">
                                        <div class="col-md-4 form-control form-control-sm" :style="{ backgroundColor: color.color, border : `3px solid ${activeColor === color ? 'black' : 'transparent'}`}"></div>
                                        <div class="col-md-8" :class="activeColor === color && 'font-weight-bold'">
                                            {{ color.label }}
                                        </div>
                                    </li>
                                    <li class="mb-2 row" @click="activeColor = null">
                                        <div class="col-md-4 form-control form-control-sm"></div>
                                        <div class="col-md-8" :class="activeColor === null && 'font-weight-bold'">
                                            Aucune couleur
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </form>
                    </div>
                    <div class="modal-footer">
                        <label v-if="colorLocation === 'window'">
                            <input type="checkbox" v-model="other" />
                            Autre couleur
                        </label>
                        <button type="button" class="btn btn-secondary" v-on:click="$store.commit('hideModal')">Annuler</button>
                        <button type="button" class="btn btn-info" @click="save"> Valider</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        props : ['colorType', 'colorLocation'],
        data() {
            return {
                activeColor : false,
                other: false,
            }
        },
        computed: {
            activeColors() {
                const appliesTo = this.colorLocation === 'window' ? null : this.colorLocation
                if (this.other) {
                    if (this.colorType) {
                        return this.$store.state.colors.items.filter(c => c.type === this.colorType && !c.default && c.applies_to === appliesTo)
                    }

                    return this.$store.state.colors.items.filter(c => !c.default && c.applies_to === appliesTo)
                } else {
                    if (this.colorType) {
                        return this.$store.state.colors.items.filter(c => c.type === this.colorType && c.default && c.applies_to === appliesTo)
                    }

                    return this.$store.state.colors.items.filter(c => c.default && c.applies_to === appliesTo)
                }

            }
        },
        components : {
        },
        methods: {
            save() {
                this.$emit('saveColor', {color: {...this.activeColor}, type: this.colorType})
                this.$store.commit('hideModal')
            }
        },
    }
</script>
