<template>
    <div class="row  sticky-top" style="z-index: 100">
        <h6 class=" col-12 border-bottom text-center py-3 mt-4 mb-0" style="min-height: 93px;">Liste des produits</h6>

        <div class="py-3 px-2 col-12" v-if="order && order.order_lines && order.order_lines.length">
            <ul class="list-unstyled pb-0 mb-0">
                <li class="mb-1 row" v-for="item in orderLineSorted" :key="item.id">
                    <div v-if="item.line" class="w-100 card">
                        <div class="card-header border-bottom-0 row">
                            <span class="col-8 p-0">{{ item.line.ref ? item.line.ref : '-' }}</span>
                            <span class="col-4 pr-0 text-right" v-if="item.line.product">
                                <!-- EDIT LINE -->
                                <button v-if="currentProductEdit === item.line_id" class="btn p-0" @click="$store.commit('clearErrors'); $store.commit('setProduct', null); $router.push(`/devis/${item.order_id}`)" title="Annuler la modification">
                                    <i class="fas fa-ban small"></i>
                                </button>
                                <button v-else class="btn p-0" @click="editProduct(item)" title="Modifier le produit">
                                    <i class="fas fa-pen small"></i>
                                </button>
                                <!-- COPY LINE -->
                                <button class="btn p-0 ml-2" @click="$store.dispatch('saveLine', {...item.line, id : null, ref: '', line_type : 'product', order_id : order.id})" title="Copier le produit">
                                    <i class="fas fa-clone small"></i>
                                </button>
                                <!-- DELETE LINE -->
                                <button class="btn p-0 ml-2" @click="$store.dispatch('deleteLine', item)" title="Supprimer le produit">
                                    <i class="fas fa-trash small"></i>
                                </button>
                            </span>
                            <span class="col-4 pr-0 text-right" v-else>
                                <!-- EDIT LINE -->
                                <button v-if="currentProductEdit === item.line_id" class="btn p-0" @click="$store.commit('setProduct', null); $router.push(`/devis/${item.order_id}`)" title="Annuler la modification">
                                    <i class="fas fa-ban small"></i>
                                </button>
                                <button v-else class="btn p-0" @click="$store.commit('clearErrors'); $store.commit('setProduct', item); $router.push(`/devis/${item.order_id}/perso`)" title="Modifier le produit">
                                    <i class="fas fa-pen small"></i>
                                </button>

                                <!-- DELETE LINE -->
                                <button class="btn p-0 ml-2" @click="$store.dispatch('deleteLine', item)" title="Supprimer le produit">
                                    <i class="fas fa-trash small"></i>
                                </button>
                            </span>

                        </div>
                        <div class="card-body border-top p-2">
                            <table class="table table-sm small">
                                <tbody>
                                    <tr>
                                        <td class="border-0" colspan="5" style="width: 55%;">{{ item.line.product ? item.line.product.code : item.line.label }}</td>
                                        <td class="border-0">x{{ item.line.quantity }}</td>
                                        <td class="text-right border-0">
                                            {{ item.estimate_price && item.line.ht_custom_price ? ((parseInt(item.estimate_price)  / 100) + item.line.ht_custom_price).toLocaleString('fr', { minimumFractionDigits: 2 }) : (parseInt(item.estimate_price ? item.estimate_price : item.price)  / 100).toLocaleString('fr', { minimumFractionDigits: 2 })  }}&nbsp;€ <br />

                                            <i class="text-muted text-small">Prix achat&nbsp;: {{ item.order_price ? (parseInt(item.order_price)  / 100).toLocaleString('fr', { minimumFractionDigits: 2 }) : '-' }}&nbsp;€</i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="row" style="min-height:40px;">
                <div class="col-7 small pt-1" style="line-height: 1;">
                    <b class="font-weight-bold">Poids vitrage</b> <br />
                    <i class="small text-dark">Vitrage le plus lourd</i>
                </div>
                <span class="col-5 p-0 text-right">{{ heaviestWeight && heaviestWeight.toLocaleString('fr', { minimumFractionDigits: 2 }) }} kg</span>
            </div>
        </div>
        <div class="py-3 px-2 text-muted small text-center w-100" v-else>
            <span>Aucun produit sélectionné</span>
        </div>
        <div class="col-12 border-top py-4">

            <div class="row" style="min-height:40px;">
                <div class="col-7 small pt-1" style="line-height: 1;">
                    <b class="font-weight-bold">Total HT</b> <br />
                    <i class="small text-dark">Marges comprises</i>
                </div>
                <span class="col-5 p-0 text-right">{{ ht_with_custom_price && ht_with_custom_price.toLocaleString('fr', { minimumFractionDigits: 2 }) }} €</span>
            </div>
            <div class="row" style="min-height:40px;"><b class="col-7 small font-weight-bold pt-1">Total TVA <span v-if="order">({{ order.vat }} %)</span></b> <span class="col-5 p-0 text-right"> {{ tva.toLocaleString('fr', { minimumFractionDigits: 2 }) }} €</span>  </div>
            <div class="row" style="min-height:40px;">
                <div class="col-7 small pt-1" style="line-height: 1;">
                    <b class="font-weight-bold">Total TTC</b> <br />
                    <i class="small text-dark">Marges comprises</i>
                </div>
                <span class="col-5 p-0 text-right"> {{ ttc.toLocaleString('fr', { minimumFractionDigits: 2 }) }} €</span>
            </div>
            <div class="row text-primary"><b class="col-7 small font-weight-bold pt-1">Marge commerciale HT</b> <span class="col-5 p-0 text-right">{{ marge_ht.toLocaleString('fr', { minimumFractionDigits: 2 }) }} €</span> </div>
        </div>

        <a v-if="order && $store.state.orders.currentProduct" class="btn btn-success col-12 disabled" disabled >
                Terminer le devis
        </a>
        <a v-else-if="order" class="btn btn-success col-12"
            :href="'/pdf/quote/' + order.id"
            target="_blank" style='color: white;'
            @click="$router.push('/devis')">
                Terminer le devis
        </a>
    </div>

</template>

<script>
    export default {

        name : 'EstimateSummary',
        props: ['order'],
        data () {
            return {
            }
        },
        computed : {
            clientTVA () {
                return this.order ? parseFloat(this.order.vat) / 100 : 0
            },
            ht () { // ESTIMATE PRICE ADD
                return this.order && this.order.order_lines ? parseInt(this.order.order_lines.map(l => l.estimate_price).reduce((a, b) => a + b, 0)) / 100 : '0.00'
            },
            ht_with_custom_price () { // ESTIMATE PRICE AND CUSTOM HT PRICE
                return this.order && this.order.order_lines && this.ht + parseInt(this.order.order_lines.filter(l => l.line && l.line.ht_custom_price).map(l => parseFloat(l.line.ht_custom_price)).reduce((a, b) => a + b, 0))
            },
            tva () { // TVA ON ht_with_custom_price (TVA FROM ORDER)
                return this.order ? Math.round(this.ht_with_custom_price * (parseFloat(this.order.vat)/ 100 ) *100 ) / 100 : '0.00'// x100 /100 to round decimal
            },
            ttc () {
                return this.order ? Math.round((this.ht_with_custom_price * this.clientTVA + this.ht_with_custom_price) * 100) / 100 : 0
            },
            marge_ht () {
                if (!this.order) {
                    return 0;
                }
                const est_price = this.order.order_lines.reduce((a, b) => a + parseInt(b.estimate_price), 0)
                const order_price = this.order.order_lines.reduce((a, b) => a + parseInt(b.order_price), 0)
                return Math.round(est_price - order_price)/100;
            },

            heaviestWeight() {
                if (!this.order) {
                    return 0;
                }

                const w = this.order.order_lines.map((ol) => {
                    const line = ol.line;
                    let weights;
                    ol.weight = 0;
                    if (line && line.product_line_parts && line.product) {
                        weights = line.product_line_parts.map((p) => {
                            const weight = parseFloat(p.part_fill.weight)
                            if (p.part_fill) {
                                return (line.width - line.product.ded_filling_width) * (p.height - line.product.ded_filling_height) * weight / 1000000 / (line.product && line.product.vtl ? line.product.vtl : 1);
                            }
                            return 0;
                        }).sort((ol1, ol2) => {
                            return ol1 > ol2 ? -1 : 1;
                        })

                        if (weights.length) {
                            ol.weight = weights[0];
                        }
                    }


                    return ol;
                }).sort((ol1, ol2) => {
                    return ol1.weight > ol2.weight ? -1 : 1;
                })

                console.log('w', w)

                if (w.length > 0) {
                    return Math.round(w[0].weight*100) / 100;
                }

                return 0;
            },
            currentProductEdit () {
                return this.$store.state.orders.currentProduct && this.$store.state.orders.currentProduct.line && this.$store.state.orders.currentProduct.line.id
            },
            orderLineSorted () {
                if (!this.order) {
                    return [];
                }
                // return this.order.order_lines.sort((a, b) => a.line.ref.localeCompare(b.line.ref))
                return this.order.order_lines.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            },
        },
        mounted () {
        },
        methods : {
            editProduct(item) {
                console.log('edit product', item)
                console.log('payload', {...item.line.product, line : item.line, order_line_id : item.id})
                this.$store.commit('clearErrors');
                this.$store.commit('setProduct', {...item.line.product, line : item.line, order_line_id : item.id});
                this.$router.push(`/devis/${item.order_id}/altech/etape2`)
            }
        },
        components: {
        },
    }
</script>

<style>

</style>
