import axios from 'axios'

export default {
    state: { items: null },
    mutations: {
        setTraverses (state, items) {
            state.items = items;
        },
    },
    actions: {
        getTraverses({ commit }) {
            const req = axios.get('/api/traverses');

            req.then(r => {
                commit('setTraverses', r.data);
            }).catch(err => {
                console.log('could not get traverses', err)
            });

            return req;
        }
    }
};