<template>
    <div class="modal-backdrop" @click.prevent="$emit('close')">
        <div class="modal" tabindex="-1" role="dialog" style="display: block" >
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            {{ title }}
                        </h5>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="$emit('close')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body text-center">
                        <img class="card-img-top" :src="image"  style="max-height: 400px;width: auto;"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props : ['image', 'title'],
    }
</script>
