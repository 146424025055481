<template>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="downloadfiles" style="top:initial; display:block;">
        <!-- DOWNLOAD DEVIS -->
        <a class="dropdown-item small" v-if="!isCustomOrder && isClient" :href="'/pdf/quote/' + currentOrder.id" target="_blank"><i class="fas fa-file-alt small mr-2"></i>Télécharger le devis</a>
        <!-- DOWNLOAD LIVRAISON -->
        <a class="dropdown-item small" v-if="delivery_document" :href="`/api/orders/${this.currentOrder.id}/documents/${delivery_document.id}`" target="_blank"><i class="fas fa-file-alt small mr-2"></i>Télécharger le bon de livraison</a>
        <!-- DOWNLOAD ARC -->
        <a class="dropdown-item small" v-if="arc_document" :href="`/api/orders/${this.currentOrder.id}/documents/${arc_document.id}`"><i class="fas fa-file-alt small mr-2"></i>Télécharger l'ARC</a>
        <!-- DOWNLOAD FACTURE -->
        <a class="dropdown-item small" v-if="bill_document" :href="`/api/orders/${this.currentOrder.id}/documents/${bill_document.id}`" target="_blank"><i class="fas fa-file-alt small mr-2"></i>Télécharger la facture</a>
        <!-- ANNULE -->
        <a class="dropdown-item text-danger small" v-if="!isClient && currentOrder.status_id !== 9" title="Annuler" @click="cancelOrder()">
            <i class="fas fa-times mr-2"></i> Annuler la commande
        </a>
        <!-- ARCHIVE -->
        <a class="dropdown-item text-primary small" v-if="!isClient && currentOrder.status_id !== 9" title="Archiver" @click="archiveOrder()">
            <i class="fas fa-archive small mr-2"></i> Archiver la commande
        </a>
        <!-- SHOW NO OPTION -->
        <div class="small text-center font-italic" v-if="isClient && isCustomOrder && !delivery_document && !arc_document && ! bill_document">Aucune option disponible</div>
    </div>
</template>

<script>
    export default {
        props : ['currentOrder', 'isClient'],
        name: 'OrderOptions',
        data() {
            return {

            }
        },
        computed: {
            arc_document () {
                return this.currentOrder.documents.find(d => d.type === 'arc')
            },
            bill_document () {
                return this.currentOrder.documents.find(d => d.type === 'bill')
            },
            delivery_document () {
                return this.currentOrder.documents.find(d => d.type === 'delivery')
            },
            isCustomOrder () {
                return this.currentOrder.documents.find(d => d.type === 'custom_order')
            },
        },
        methods : {
            cancelOrder() {
                if (confirm(`Souhaitez-vous annuler la commande n°${this.currentOrder.id} ?`)) {
                    this.$store.dispatch('storeOrder', {...this.currentOrder, status_id : 8})
                }
            },
            archiveOrder() {
                if (confirm(`Souhaitez-vous archiver la commande n°${this.currentOrder.id} ?`)) {
                    this.$store.dispatch('storeOrder', {...this.currentOrder, status_id : 9})
                }
            },
        },
        components: {
        },
    }
</script>