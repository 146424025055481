<template>
    <div class="modal-backdrop">
        <div class="modal" tabindex="-1" role="dialog" style="display: block" >
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modal_edit_incident">
                            <b>Ajouter une ligne</b>
                        </h5>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="$store.commit('hideModal')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form @submit.prevent="save">
                            <div class="row p-4">
                                <div class="mb-3 col-lg-12 text-center">
                                    <button class="btn-flat btn btn-outline-secondary px-4 py-2 font-weight-bold" @click="altech">Ajouter un produit Altech</button>
                                    <div class="my-2"> OU </div>
                                    <button class="btn-flat btn btn-outline-secondary px-4 py-2 font-weight-bold" @click="custom">Ajouter ligne personnalisée</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        props : ['order'],
        data() {
            return {

            }
        },
        validations: {
        },
        computed: {
        },
        components : {
        },
        methods: {
            altech() {
                this.$store.commit('hideModal');
                this.$router.push(`/devis/${this.order.id}/altech`)
            },
            custom() {
                this.$store.commit('hideModal');
                this.$router.push(`/devis/${this.order.id}/perso`)
            },
        },
        mounted() {
        },
    }
</script>
