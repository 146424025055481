<template>

    <div class="row">
        <div class="row pt-2 py-4 my-1 col-12">
            <div class="col-4"><a href="#" class="btn btn-tool" v-on:click.prevent="$router.push('/devis')"><i class="fas fa-chevron-left"></i> Retour à la liste des devis</a></div>
        </div>
        <div class="col-md-8 py-2">
            <div v-if="order" class="pb-4 text-center border-bottom mb-4">
                <h3>{{ order.title }}</h3>
                <span v-if="order.contact">
                    Client : <strong class="bold">{{ order.contact }}</strong>
                    <span v-if="order.manager || order.address || order.cpcity">
                        &nbsp;&mdash;&nbsp;
                    </span>
                </span>
                <span v-if="order.manager">
                    Affaire suivie par : <strong class="bold">{{ order.manager }}</strong>
                    <span v-if="order.address || order.cpcity">
                        &nbsp;&mdash;&nbsp;
                    </span>
                </span>
                <span v-if="order.address || order.cpcity">
                    Adresse : <strong class="bold">{{ order.address }} {{ order.cpcity }}</strong>
                </span>
                <button class="btn p-0 ml-2" @click="$store.commit('showModal', 'editEstimate')"  style="font-size: 16px;" title="Modifier">
                    <i class="fas fa-pen"></i>
                </button>
            </div>

        <router-view></router-view>
        </div>
        <div class="col-md-4 border-left pl-4">
            <EstimateSummary :order="order"></EstimateSummary>
        </div>

        <NewProductModal :order="order" v-if="modal === 'newProduct'"></NewProductModal>
        <NewEstimateModal :currentOrder="order" v-if="modal === 'editEstimate'"></NewEstimateModal>
    </div>
</template>

<script>
    import NewEstimateModal from './NewEstimateModal.vue'
    import NewProductModal from './NewProductModal.vue'
    import EstimateSummary from './EstimateSummary.vue'
    export default {

        name : 'EditEstimate',
        props: [],
        data () {
            return {
            }
        },
        computed : {
            user: function () {
                return this.$store.state.user.data
            },
            orders () {
                return this.$store.state.orders.items
            },
            order() {
                return this.orders ? this.orders.find(o => o.id == this.$route.params.id) : null
            },
            modal() {
                return this.$store.state.modals.shown
            }
        },
        mounted () {
        },
        methods : {

        },
        watch : {
        },
        components: {
            NewProductModal,
            EstimateSummary,
            NewEstimateModal,
        },
    }
</script>

<style>

</style>