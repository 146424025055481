<template>
    <div class="modal-backdrop">
        <div class="modal" tabindex="-1" role="dialog" style="display: block" >
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <b> {{currentOrder ? `Modification de la commande n°${currentOrder.id}` : 'Nouvelle commande'}} </b>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="$store.commit('hideModal')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <OrderForm :currentOrder="editingOrder" ref="editorderform"></OrderForm>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" v-on:click="$store.commit('hideModal')">Annuler</button>
                        <button type="button" class="btn btn-info" @click="save"><i class="fa fa-spinner fa-spin" v-if="saving"></i> Valider</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import OrderForm from './OrderForm.vue'

    export default {
        props: ['currentOrder'],
        data() {
            return {
                saving: false,
                editingOrder: this.currentOrder,
            }
        },

        watch: {
            currentOrder() {
                this.editingOrder = this.currentOrder;
            }
        },
        components : {
            OrderForm,
        },

        methods: {
            async save() {
                this.saving = true;
                const res = await this.$refs.editorderform.save();

                this.saving = false;
                if (res) {
                    this.$store.dispatch('storeOrder', res)
                    this.$store.commit('hideModal');
                }
            }
        },

        mounted() {
        },
    }
</script>
