<template>
    <nav class="navbar navbar-expand-lg bg-light border-top" :class="user.role && user.role.slug === 'admin' ? 'border-bottom' : ''">
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item" v-if="user && user.role && user.role.slug === 'admin'">
                    <router-link class="nav-link text-uppercase px-4" style="cursor:pointer;" to="/" exact>Gestion des commandes</router-link>
                </li>
                <li class="nav-item" v-if="user && user.role && user.role.slug === 'admin'">
                    <router-link class="nav-link text-uppercase px-4" style="cursor:pointer;" to="/gestion-comptes">Gestion des comptes</router-link>
                </li>

            </ul>
        </div>
    </nav>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        mounted(){
            console.log('user', this.$store.state.user)
        },
        computed: {
            user() {
                return this.$store.state.user.data;
            }
        },
        components: {
        },
    }

</script>


<style scoped>
    .router-link-active {
        font-weight: bold;
    }
</style>