<template>
    <div class="row">
        <ul class="list-unstyled pb-0 mb-0 col-12">
            <li class="mb-2 row">
                <div class="col-md-4" :class="{ 'text-danger': submitted && $v.label.$error }">Libellé* : </div>
                <div class="col-md-6">
                    <input type="text" class="form-control form-control-sm" v-model="label" :class="{ 'is-invalid': submitted && $v.label.$error }" />
                    <div class="text-danger small" v-if="submitted && $v.label.$error"><b>Libellé obligatoire</b></div>
                </div>
            </li>
            <li class="mb-2 row">
                <div class="col-md-4" :class="{ 'text-danger': submitted && $v.ref.$error }">Repère* : </div>
                <div class="col-md-6">
                    <input type="text" class="form-control form-control-sm" v-model="ref" :class="{ 'is-invalid': submitted && $v.ref.$error }" />
                    <div class="text-danger small" v-if="submitted && $v.ref.$error"><b>Référence obligatoire</b></div>
                </div>
            </li>
            <li class="mb-2 row">
                <div class="col-md-4">Informations : </div>
                <div class="col-md-6">
                    <input id="firstname" type="text" class="form-control form-control-sm" v-model="info" />
                </div>
            </li>
            <li class="mb-2 row">
                <div class="col-md-4" :class="{ 'text-danger': submitted && $v.price.$error }">Prix (unité)* : </div>
                <div class="col-md-6">
                    <input  type="number" class="form-control form-control-sm" v-model="price" :class="{ 'is-invalid': submitted && $v.price.$error }" />
                    <div class="text-danger small" v-if="submitted && $v.price.$error"><b>Prix obligatoire</b></div>
                </div>
            </li>
            <li class="mb-2 row">
                <div class="col-md-4" :class="{ 'text-danger': submitted && $v.quantity.$error }">Quantité* : </div>
                <div class="col-md-6">
                    <input  type="number" class="form-control form-control-sm" v-model="quantity" :class="{ 'is-invalid': submitted && $v.quantity.$error }" />
                    <div class="text-danger small" v-if="submitted && $v.quantity.$error"><b>Quantité obligatoire</b></div>
                </div>
            </li>
        </ul>
        <div class="col-6 mt-4">
            <button @click="$store.commit('setProduct', null); $router.push(`/devis/${order.id}`)" class="btn btn-secondary btn-flat">Annuler</button>
        </div>
        <div class=" col-6 text-right mt-4">
                <button class="btn btn-info btn-flat" @click="saveProduct">Ajouter le produit</button></div>
        </div>
</template>
<script>
    import { required } from 'vuelidate/lib/validators'

    export default {
        data() {
            return {
                submitted : false, 

                ref : null,
                price:null,
                quantity:null,
                label:null,
                info:null,
            }
        },
        validations: {
            ref : {required},
            label : {required},
            price : {required},
            quantity : {required},
        },
        mounted(){
            this.currentProduct && this.settingProduct();
            !this.currentProduct && this.$store.commit('setProduct', {ref : null, price : null, quantity:null,label:null,info:null})
        },
        computed: {
            order () {
                return this.$store.state.orders.items && this.$store.state.orders.items.find( o => o.id == this.$route.params.id)
            },
            currentProduct () {
                return this.$store.state.orders.currentProduct
            },
            
            
        },
        methods: {
            settingProduct(){
                this.ref = this.currentProduct.line.ref;
                this.price = parseInt(this.currentProduct.line.price)/100;
                this.quantity = this.currentProduct.line.quantity;
                this.info = this.currentProduct.line.info;
                this.label = this.currentProduct.line.label;
            },
            saveProduct () {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.submitted = true
                    return 
                }
                this.$store.dispatch('saveLine', {
                    order_id : this.order.id,
                    order_line_id : this.currentProduct &&  this.currentProduct.id ? this.currentProduct.id : null,
                    line_type : 'custom',//App\Models\CustomLine
                    line_id : this.currentProduct &&  this.currentProduct.line_id ? this.currentProduct.line_id : null,
                    ref : this.ref,	
                    price:`${this.price*100}`,
                    quantity : this.quantity,
                    info: this.info,
                    label: this.label,  
                })
                
            },
            
        }
    }
</script>