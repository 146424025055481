<template>
    <div class="wrapper" v-if="isClient">
        <div class="content-wrapper bg-light">
            <div class="container">
                <router-view></router-view>
            </div>
        </div>
        <NewEstimateModal v-if="modal === 'newEstimate'"></NewEstimateModal>
    </div>
</template>

<script>
    import NewEstimateModal from './NewEstimateModal.vue'
export default {
    name : 'Orders',
    data () {
        return {

        }
    },
    computed : {
        user: function () {
            return this.$store.state.user.data;
        },
        isClient () {
            return this.user && this.user.role.slug === 'client'
        },
        modal() {
            return this.$store.state.modals.shown
        }
    },
    mounted () {
    }, 
    methods : {

    },
    components: {
        NewEstimateModal,
    }

}
</script>

<style>

</style>