<template>
    <div class="wrapper">
        <div class="content-wrapper bg-light">
            <div class="container">
                <div class="row pt-5 pb-5">
                    <div class="fields col-md-12">
                        <div v-if="user && user.role && user.role.slug === 'client'">
                            <h3>Tableau de bord</h3>
                            <div class="row">
                                <div class="col-12 my-4">
                                    <Alert></Alert>
                                </div>
                                <Estimates></Estimates>
                                <Orders></Orders>
                                <Account></Account>
                            </div>
                        </div>
                        <div v-if="user && user.role && user.role.slug === 'admin'">
                            <OrdersList></OrdersList>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NewEstimateModal v-if="modal === 'newEstimate'"></NewEstimateModal>
    </div>
</template>
<script>
    import NewEstimateModal from '../estimates/NewEstimateModal.vue'
    import Account from './Account.vue'
    import Estimates from './Estimates.vue'
    import Orders from './Orders.vue'
    import Alert from './Alert.vue'
    import OrdersList from '../orders/List.vue'

    export default {
        name: 'Home',
        data() {
            return {

            }
        },
        computed: {
            user() {
                return this.$store.state.user.data;
            },
            modal() {
                return this.$store.state.modals.shown
            }
        },
        components: {
            Account,
            Estimates,
            Alert,
            Orders,
            NewEstimateModal,
            OrdersList,
        }
    }
</script>