<template>
    <div class="modal-backdrop">
        <div class="modal" tabindex="-1" role="dialog" style="display: block" >
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modal_edit_incident">
                            <b>Ajouter une commande</b>
                        </h5>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="$store.commit('hideModal')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="row p-4">
                            <!-- CHOOSE OPTIONS -->
                            <div class="mb-3 col-lg-12 text-center" v-if="!importMode">
                                <button class="btn-flat btn btn-outline-secondary px-4 py-2 font-weight-bold" @click="importMode = 'devis'">Ajouter depuis un devis</button>
                                <div class="my-2"> OU </div>
                                <button class="btn-flat btn btn-outline-secondary px-4 py-2 font-weight-bold" @click="importMode = 'local'">Ajouter depuis un fichier local</button>
                            </div>
                            <!-- DEVIS -->
                            <div class="mb-3 col-lg-12 text-center" v-if="importMode === 'devis'">
                                <select class="form-control form-control-sm" v-model="selectedDevis">
                                    <option v-for="d in devis" :value="d" :key="d.id">{{ d.title }} - {{ d.order_lines ? parseInt(d.order_lines.map(l => l.line_type === 'App\\Models\\ProductLine' ? l.order_price : 0).reduce((a, b) => a + b, 0)) / 100 : '0.00' }} €</option>
                                </select>
                            </div>
                            <!-- LOCAL -->
                            <div class="mb-3 col-lg-12" v-if="importMode === 'local'">
                                <ul class="list-unstyled pb-0 mb-0">
                                    <li class="mb-2 row">
                                        <div class="col-md-6" :class="{ 'text-danger': submitted && $v.localOrder.title.$error}">Nom du chantier *&nbsp;: </div>
                                        <div class="col-md-6">
                                            <input v-model="localOrder.title" type="text" class="form-control form-control-sm" :class="{ 'is-invalid': submitted && $v.localOrder.title.$error}"/>
                                            <div class="text-danger small" v-if="submitted && $v.localOrder.title.$error"><b>Nom du chantier obligatoire</b></div>
                                        </div>
                                    </li>
                                    <li class="mb-2 row">
                                        <div class="col-md-6">Nom du contact&nbsp;: </div>
                                        <div class="col-md-6">
                                            <input v-model="localOrder.contact" type="text" class="form-control form-control-sm"/>
                                        </div>
                                    </li>
                                    <li class="mb-2 row">
                                        <div class="col-md-6">Lieu de livraison&nbsp;: </div>
                                        <div class="col-md-6">
                                            <input v-model="localOrder.address" type="text" class="form-control form-control-sm"/>
                                        </div>
                                    </li>
                                    <li class="mb-2 row">
                                        <div class="col-md-6">CP / Ville&nbsp;: </div>
                                        <div class="col-md-6">
                                            <input v-model="localOrder.cpcity" type="text" class="form-control form-control-sm"/>
                                        </div>
                                    </li>

                                    <li class="mb-2 row">
                                        <div class="col-md-6" :class="{ 'text-danger': submitted && $v.localOrder.document.$error}">Télécharger la commande *&nbsp;: </div>
                                        <div class="custom-file col-md-6">
                                            <input type="file" class="custom-file-input" id="purchase_order" lang="fr" @change="setDocument" :class="{ 'is-invalid': submitted && $v.localOrder.document.$error}">
                                            <label class="custom-file-label"  for="purchase_order">{{ (localOrder.document && localOrder.document.name ? localOrder.document.name : '') }}</label>
                                            <div class="text-danger small" v-if="submitted && $v.localOrder.document.$error"><b>Fichier obligatoire</b></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="$store.commit('hideModal')">Annuler</button>
                        <button v-if="importMode" type="button" class="btn btn-info" @click="save">
                            <i class="fas fa-spinner fa-spin" v-if="saving"></i>
                            Valider
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { required } from 'vuelidate/lib/validators'

    export default {
        props : ['isClient', 'clients'],
        data() {
            return {
                submitted : false,
                importMode : null,
                selectedDevis : null,
                localOrder : {
                    title : null,
                    contact : null,
                    address : null,
                    cpcity : null,
                    vat : null,
                    price : null,
                    manager : null,
                    first_visit_date : null,
                    user_id : this.$store.state.user.data.id,
                    document : null,
                },
                saving: false,
            }
        },
        validations: {
            localOrder : {
                title : {required},
                // vat : {required},
                // price : {required},
                document : {required},
            },
        },
        computed: {
            devis () {
                return this.$store.state.orders.items.filter(o => o.is_estimate)
            }
        },
        components : {
        },
        methods: {
            save () {
                if (this.importMode === 'local') {
                    this.$v.$touch()
                    if (this.$v.$invalid) {
                        this.submitted = true
                        return
                    }
                }

                this.saving = true;

                this.$store.dispatch('storeOrder', {...this.selectedDevis ? this.selectedDevis : this.localOrder, is_estimate : 0, status_id : 2})
                .then(res => {
                    if (this.localOrder.document) {
                        let data = new FormData();
                        data.append('document', this.localOrder.document);
                        data.append('documentable_type', 'order');
                        data.append('type', 'custom_order');
                        axios.post(`/api/orders/${res.data.id}/documents`, data)
                        .then( doc => {
                            res.data.documents.push(doc.data);
                            this.$store.commit('updateOrder', res.data)
                            this.saving = false;
                            this.$store.commit('hideModal')
                            alert('Votre commande a bien été déposé. Vous allez recevoir un mail de confirmation.')
                        })
                    } else {
                        this.$store.commit('hideModal')
                        alert('Votre commande a bien été déposé. Vous allez recevoir un mail de confirmation.')
                    }
                })

            },
            setDocument(ev) {
                if (ev.target.files.length) {
                    this.localOrder.document =  ev.target.files[0]
                }
            },
        },
        mounted() {
        },
    }
</script>
