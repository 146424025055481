import axios from 'axios'

export default {
    state: { items: null },
    mutations: {
        setAccessories (state, acc) {
            state.items = acc;
        },
    },
    actions: {
        getAccessories({ commit }) {
            const req = axios.get('/api/accessories');

            req.then(r => {
                commit('setAccessories', r.data);
            }).catch(err => {
                console.log('could not get fills', err)
            });

            return req;
        }
    }
};