<template>
    <div>
        <div class="row pt-2 py-2 my-1" v-if="isClient">
            <div class="col-4"><a href="#" class="btn btn-tool" v-on:click.prevent="$router.push('/')"><i class="fas fa-chevron-left"></i> Retour</a></div>
        </div>
        <div class="row pt-5 pb-5">
            <div class="fields col-md-12">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card m-0" style="background: transparent;box-shadow: none;">
                            <div class="py-0">
                                <div class="row mt-3">
                                    <div class="pt-4" :class="isClient ? 'col-3' : 'col-6'">
                                        <h2 class="card-title">
                                            <span class="ml-2">
                                                Commandes ({{ orders.length }})
                                            </span>
                                        </h2>
                                    </div>
                                    <div class="col-3 pt-4" v-if="isClient">
                                        <button type="button" class="btn btn-outline-infox btn-sm text-info" @click="$store.commit('showModal', 'newOrder')"> <!--   v-if="isClient" -->
                                            <i class="fas fa-plus"></i>  <span class="ml-1"> Créer une commande</span>
                                        </button>
                                    </div>
                                    <div class="col-2">
                                        <div class="form-group">
                                            <label for="" class="small mb-0">Statut</label>
                                            <select class="form-control form-control-sm" v-model="filterByStatus">
                                                <option value=''>Tous</option>
                                                <option v-for="status in statuses" :value="status.id" :key="status.id">
                                                    {{ status.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="form-group">
                                            <label for="" class="small mb-0">Filtrer</label>
                                            <select class="form-control form-control-sm" v-model="filterBy"> <!--  @change="sortIncidents()" -->
                                                <option value=''>Tout afficher</option>
                                                <option v-for="client in clients" :value="client.id" :key="client.id">
                                                    <!-- {{ client.account && client.account.name }} -->
                                                    {{ client.account.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-2">
                                        <div class="form-group">
                                            <label for="" class="small mb-0">Trier</label>
                                            <select class="form-control form-control-sm" v-model="orderBy">
                                                <option v-for="(item, index) in orderChoice" :value="item.property" :key="index">
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-white">

                                <table class="table table-borderedx table-striped bg-white" style="font-size: 15px;">
                                    <thead class="text-uppercase">
                                    <tr>
                                        <th class="small border-top-0 py-1">N°</th>
                                        <th class="small border-top-0 p-1">Date de création</th>
                                        <th class="small border-top-0 p-1">Nom du chantier</th>
                                        <th class="small border-top-0 p-1">Société</th>
                                        <th class="small border-top-0 p-1">Statut</th>
                                        <!-- <th class="small border-top-0 p-1">Prix</th> -->
                                        <th class="small border-top-0 p-1">Date livraison</th>
                                        <th class="small border-top-0 p-1"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in orders" :key="item.id">
                                        <td> {{ item.id }} </td>
                                        <td class="px-1"> {{ frenchDate(item.created_at) }} </td>
                                        <td class="px-1"> {{ item.title }} </td>
                                        <td class="px-1"> {{ item.user.account.name ? `${item.user.account.name}` : ''}} </td>

                                        <!-- PROGRESS BAR OR STATUS -->
                                        <td class="px-1" v-if="item.status_id === 9"> {{ item.status && item.status.name }}</td>
                                        <td class="px-1" v-else-if="isClient" style="max-width: 160px;">
                                            <ProgressBar :status="item.status" :title="item.status.name"></ProgressBar>
                                        </td>
                                        <td class="px-1 py-1" v-else style="max-width: 160px;">
                                            <select class="form-control form-control-sm small" v-model="item.status_id" @change="$store.dispatch('storeOrder', item)">
                                                <option v-for="status in statuses.filter(s => s.slug !== 'archive')" :value="status.id" :key="status.id">
                                                    {{ status.name }}
                                                </option>
                                            </select>
                                        </td>

                                        <!-- <td class="px-1"> {{ calcOrderPrice(item) ? `${calcOrderPrice(item)} €` : item.price && `${item.price} €` }}  </td> -->
                                        <td class="px-1"> {{ frenchDate(item.delivery_date) }} </td>
                                        <td class="px-2 py-2 text-right">
                                            <button class="btn p-0" v-if="isClient" title="Pause">

                                                <!-- Le client peut mettre en stand by avant que l’ARC ne soit déposé par Altech. -->
                                                <i class="fas fa-play small"  v-if="item.status_id == 7" @click="$store.dispatch('storeOrder', {...item, status_id : 2})"></i>
                                                <i class="fas fa-pause small" v-else-if="!item.documents.find(d => d.documentable_type === 'arc_document') && item.status_id < 4" @click="$store.dispatch('storeOrder', {...item, status_id : 7})"></i>
                                            </button>

                                            <button class="btn p-0 ml-2" v-if="!isClient" @click="currentOrder = item;$store.commit('showModal', 'order')" title="Modifier">
                                                <i class="fas fa-pen small"></i>
                                            </button>
                                            <button class="btn p-0 ml-2" title="Télécharger la commande">
                                                <a v-if="getCustomDocument(item)" :href="getCustomDocument(item).url" target="_blank" style='color: black;'><i class="fas fa-download small"></i></a>
                                                <a v-else :href="'/pdf/order/' + item.id" target="_blank" style='color: black;'><i class="fas fa-download small"></i></a>
                                            </button>

                                            <!-- Dropdown download files -->
                                            <button id="downloadfiles" @click="openDropdown = (openDropdown === item.id) ? null : item.id" type="button" class="btn btn-outline-infox dropdown-togglex btn-sm ml-1" data-toggle="dropdown">
                                                <i class="fas fa-ellipsis-v small"></i>
                                            </button>
                                            <div style="position:fixed;z-index:999;top:0;left:0;right:0;bottom:0;" @click="openDropdown = (openDropdown === item.id) ? null : item.id" v-if="openDropdown === item.id"></div>
                                            <ListOptions :currentOrder="item" v-if="openDropdown === item.id" :isClient="isClient"></ListOptions>
                                        </td>
                                    </tr>
                                    <tr v-if="orders && !orders.length">
                                        <td style="text-align: center" colspan="8">Aucune commande ne correspond</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NewOrderModal v-if="modal === 'newOrder'" :isClient="isClient" :clients="clients"></NewOrderModal>
        <OrderModal v-if="modal === 'order'" :currentOrder="currentOrder"></OrderModal>
    </div>
</template>

<script>
    import {frenchDate} from '../../utils/dates'
    import NewOrderModal from './NewOrderModal.vue'
    import OrderModal from './OrderModal.vue'
    import ProgressBar from './ProgressBar.vue'
    import ListOptions from './ListOptions.vue'
    export default {
        name: 'OrdersList',
        data() {
            return {
                frenchDate,
                filterBy: this.isClient ? this.user.id : '',
                filterByStatus : '',
                orderBy : 'created_date',
                orderChoice: [
                                {name : 'Date de création',  property : 'created_date'},
                                {name : 'Date de livraison',  property : 'delivery_date'},
                            ],
                currentOrder : null,
                openDropdown : null,

            }
        },
        computed: {
            orders() {
                let orders = this.$store.state.orders.items ? this.$store.state.orders.items.filter(item => !item.is_estimate) : [];
                if (this.filterBy) {
                    orders = orders.filter(o => o.user_id == this.filterBy)
                }
                if(this.filterByStatus) {
                    orders = orders.filter(o => o.status_id === this.filterByStatus)
                }
                if (this.orderBy) {
                    console.log('this.orderBy', this.orderBy)
                    orders.sort((a, b) => typeof a[this.orderBy] === "string" ? a[this.orderBy].localeCompare(b[this.orderBy]) : a[this.orderBy] > b[this.orderBy] ? 1 : -1)
                }
                return orders
            },
            statuses () {
                return this.$store.state.statuses.items;
            },
            user() {
                return this.$store.state.user.data;
            },
            clients() {
                return this.$store.state.users.items.filter(u => u.role && u.role.slug === 'client');
            },
            modal() {
                return this.$store.state.modals.shown
            },
            isClient () {
                return this.user && this.user.role.slug === 'client'
            }
        },
        methods : {
            getCustomDocument (order) {
                return order.documents.find(d => d.documentable_type === 'custom_order')
            },
            calcOrderPrice (order) {
                return order.order_lines.map(o => o.line_type === 'App\\Models\\ProductLine' ? o.order_price : 0).reduce((a, b) => a + b, 0) / 100
            }
        },
        components: {
            NewOrderModal,
            OrderModal,
            ProgressBar,
            ListOptions,
        },
    }
</script>
