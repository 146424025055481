<template>
    <div style="min-height:800px">
        <h5 class="text-uppercase small mt-4 pt-4">Catégories</h5>
        <ul class="list-inline text-center row">
            <li v-for="category in categories" :key="category.id" v-if="category.categories.length" class="list-inline-item col-3">
                <div class="card pt-2" @click="chooseCategory(category.id)" :style="{ border: selectedCat && selectedCat.id === category.id ? '1px solid #138496' : '1px solid transparent'}">
                    <img v-if="category.slug" class="card-img-top" :src="`/images/product_categories/${category.slug}/${category.slug}.jpg`"  style="max-height: 80px;object-fit: contain;max-width: 260px;"/>
                    <div class="card-body py-2 px-2">
                        <button class="btn btn-xs btn-flat font-weight-bold px-2" :class="selectedCat && selectedCat.id === category.id ? 'btn-info' : 'text-info'">{{ category.name }}</button>
                    </div>
                </div>
            </li>
        </ul>
        <h5 class="text-uppercase small mt-4 pt-4">Sous-Catégories</h5>
        <ul v-if="subcategories && subcategories.length" class="list-inline text-center row">
            <li v-for="subcat in subcategories" :key="subcat.id" class="list-inline-item col-3">

                <div class="card pt-2"  @click="chooseSubCategory(subcat.id)" :style="{ border: selectedSubCat && selectedSubCat.id === subcat.id ? '1px solid grey' : '1px solid transparent'}">
                    <img v-if="selectedCat" class="card-img-top" :src="`/images/product_categories/${selectedCat.slug}/${subcat.slug}.jpg`"  style="max-height: 80px;object-fit: contain;max-width: 260px;"/>
                    <div class="card-body py-2 px-2">
                        <button class="btn btn-xs btn-flat font-weight-bold px-2" :class="selectedSubCat && selectedSubCat.id === subcat.id ? 'btn-secondary' : 'text-secondary'">{{ subcat.name }}</button>
                    </div>
                </div>
            </li>
        </ul>
        <div v-else><i class="small">Choississez une catégorie</i></div>
        <div v-if="products && products.length" class="row mb-2">
            <div class="form-group col-10">
                <h5 class="text-uppercase small mt-4 pt-4">Produits</h5>
                <v-select v-model="product" label="label" :options="products" :clearable="false" >
                    <span slot="no-options">Aucun produit disponible</span>
                </v-select>
            </div>
            <div class="col-2">
                <img v-if="product && product.img_code" class="card-img-top" :src="`/images/products/${product.img_code}.jpg`"  style="max-height: 100px;object-fit: contain;"/>
            </div>
        </div>

        <div class="row mb-4" v-if="product">
            <div class="form-group col-6">
                <h5 class="text-uppercase small mt-4 pt-4">Type de pose</h5>
                <select class="form-control form-control-sm" v-model="fit_type_id" placeholder="Sélectionnez un type de pose" v-if="selectedCat && selectedCat.slug !== 'verriere'">
                    <option v-for="type in fitTypes" :value="type.id" :key="type.id">{{ type.name }}</option>
                </select>
                <label v-else>
                    {{ fitTypes.find(t => t.id == fit_type_id).name }}
                </label>
            </div>
            <div class="col-6" v-if="fit_type_id">
                <a href="#" @click.prevent="fitModal=true">
                    <img class="card-img-top" :src="`/images/fitTypes/${fitTypes.find(t => t.id == fit_type_id).slug}.png`"  style="max-height: 100px;object-fit: contain;"/>
                </a>
            </div>
        </div>
        <div class="row my-4">
            <div class='col-6'>
                <button class="btn btn-secondary btn-flat" @click="$router.push(`/devis/${order.id}`)">Retour</button>
            </div>
            <div v-if="product && fit_type_id" class="text-right col-6">
                <button class="btn btn-info btn-flat" @click="sendProduct">Suivant</button>
            </div>
        </div>
        <FitTypeModal :fitType="fitTypes.find(t => t.id == fit_type_id)" v-if="fitModal" @close="fitModal=false"></FitTypeModal>
    </div>
</template>
<script>
    import FitTypeModal from './FitTypeModal.vue'
    export default {
        name: 'AltechProductChooser',
        data() {
            return {
                selectedCat: null,
                selectedSubCat: null,
                product: null,
                fit_type_id: null,
                fitModal: false,
            }
        },
        computed: {
            categories() {
                return this.$store.state.products.items;
            },
            subcategories() {
                return this.selectedCat ? this.selectedCat.categories : [];
            },
            products() {
                return this.selectedSubCat ? this.selectedSubCat.products : [];
            },
            fitTypes () {
                return this.$store.state.fitTypes.items
            },
            order () {
                return this.$store.state.orders.items && this.$store.state.orders.items.find( o => o.id == this.$route.params.id)
            }
        },
        methods: {
            chooseCategory(id) {
                this.selectedCat = this.categories.find(cat => cat.id === id)
                if (this.selectedCat.slug === 'verriere') {
                    const ft = this.fitTypes.find(f => f.slug === 'tunnel')
                    this.fit_type_id = ft ? ft.id : null;
                }
                this.selectedSubCat = null
                this.product = null
            },
            chooseSubCategory(id) {
                this.product = null
                this.selectedSubCat = this.subcategories.find(cat => cat.id === id)
            },
            sendProduct () {
                this.$store.commit('clearErrors');
                this.$store.commit('setProduct', {...this.product, line : {fit_type_id : this.fit_type_id, fit_type: this.fitTypes.find(f => f.id === this.fit_type_id)}})
                this.$router.push(`/devis/${this.order.id }/altech/etape2`)
            }
        },
        components: {
            FitTypeModal,
        }
    }
</script>
